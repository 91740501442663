$reservation-list-header-height: 144px;
$reservation-list-footer-height: 46px;
$reservation-list-switch-text-size: 14px;

.reservation-list {
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  position: relative;

  @include media-breakpoint-up("md") {
    margin: 0 0 0 0;
  }

  .reservation-list-header {
    padding: $base-padding * 2 $base-padding * 2;
    box-shadow: $shadow-offset $shadow-color;
    background-color: #f0f5f7;

    position: relative;
    margin-bottom: $base-margin;

    .MuiCollapse-wrapperInner.MuiCollapse-vertical {
      display: flex;
      flex-direction: column;
      gap: $base-padding;
    }

    &__content {
      display: flex;
      align-items: center;
      padding-bottom: $base-padding;
      margin: 0 $base-padding * (-2);

      .reservation-list-date {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        padding-right: $base-padding * 2;

        & > .icon {
          margin: 0 #{$base-margin};
        }

        .localina-datefield {
          padding-bottom: 0;
          max-width: 150px;
          min-width: 100px;
        }

        .localina-button {
          font-size: 11px;
          width: auto;
        }

        @include media-breakpoint-down("sm") {
          justify-content: flex-start;
        }
      }

      .localina-iconbutton {
        background-color: $secondary-button-background-color;
        border: $border-size solid $border-color;
        border-radius: $border-radius;

        .icon {
          display: flex;

          &.notifications-available path {
            fill: $notification-color;
          }
        }
      }
    }

    .localina-collapse-button {
      z-index: 1;
      border-radius: 50%;
      position: absolute;
      padding: $base-padding/2;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      background-color: #f0f5f7;
      box-shadow: inset 0 3px 4px 0 rgba(0, 0, 0, 0.25);
      cursor: pointer;

      .icon svg {
        height: $icon-size-medium;
        width: $icon-size-medium;
      }
    }

    &__text-filter {
      width: 100%;

      .localina-inputfield {
        padding: 0;
      }

      input {
        padding: 8px 8px;
      }
    }

    &__switch {
      margin-top: -$base-padding;

      .MuiFormControlLabel-label {
        font-size: $reservation-list-switch-text-size;
        flex-grow: 0;
      }

      .localina-switch {
        padding-bottom: $base-padding;
      }
    }

    .button-group-style {
      overflow: auto;

      .unassigned-shifts:not(.selected) {
        background-color: $error-color;
        color: white;
      }
    }
  }

  .reservation-list-body {
    flex: 1;
    margin-top: $shadow-size;
    overflow-y: auto;

    .empty-case {
      padding: $base-padding * 2;

      .clear-filter-link {
        text-decoration: underline;
        color: blue;
        cursor: pointer;
      }
    }

    .swiper {
      height: 100%;

      .swiper-content {
        @include media-breakpoint-down("md") {
          padding-bottom: #{$base-padding * 5};
        }
      }
    }

    &__content {
      padding-bottom: #{$fabbutton-size - $base-padding * 2};
    }

    .unassigned-reservations-info {
      background-color: white;
      color: $error-color;
      border: none;
      font-weight: bold;
      height: auto;
      padding: 8px 0;

      .MuiChip-label {
        white-space: normal;
      }
    }
  }
}

.left-side-reservation-footer {
  display: flex;
  position: absolute;
  justify-content: flex-end;
  right: $base-margin;
  bottom: #{$base-margin * 2};
  pointer-events: none;

  &.portal-enabled {
    left: $base-margin;
    right: unset;
  }

  .localina-speeddial #create-actions {
    $scale-fab-buttons: 1.3;
    $scale-tooltip-label: 0.6;

    & .MuiSpeedDialAction-staticTooltipLabel {
      height: calc($speeddial-height * $scale-tooltip-label);
      inset: auto;
      right: 100%;
      margin: 8px 0 8px;
      display: flex;
      justify-content: center;
    }

    & .MuiFab-root {
      height: calc($speeddial-height * $scale-fab-buttons);
      width: calc($speeddial-width * $scale-fab-buttons);
    }
  }
}
