.localina-tabs {
  .localina-tab {
    color: $tab-label-color;

    .localina-badge {
      position: absolute;
      width: 100%;
      height: 100%;
      justify-content: center;

      .MuiBadge-badge:not(.MuiBadge-invisible) {
        background-color: $error-color;
        top: 0;
        right: 0;
        font-size: 16px;
        color: white;
        transform: unset;
        transition: unset;
      }
    }

    &.Mui-selected {
      color: $tab-selected-label-color;
    }
  }

  .localina-label {
    text-transform: none;
  }

  .MuiTabs-indicator {
    background-color: $tab-indicator-background-color;
    height: $tab-indicator-size;
  }
}
