.guests {
  #guests {
    height: calc(100% - #{$base-padding * 3});
    padding-bottom: #{$base-padding * 3};
    display: flex;
    flex-direction: column;
  }

  .guests-filters {
    padding: #{$base-padding * 3};
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);

    @include media-breakpoint-up("sm") {
      .localina-sortfield {
        width: 50%;
        margin-left: auto;
      }
    }
  }

  .infinite-scroll-component {
    > div:not(.localina-spinner) {
      justify-content: flex-end;
    }
  }

  .loading-spinner {
    flex: 1;
    display: flex;
    position: relative;
    justify-content: center;
  }

  .phone-number-label {
    svg,
    p {
      color: black;
    }
  }

  $phone-email-icon-size: 14px;

  .localina-label.has-icon {
    flex-direction: row-reverse;
    gap: $base-padding;
    justify-content: flex-end;

    p {
      margin-left: 0;
    }

    svg {
      width: $phone-email-icon-size;
      height: $phone-email-icon-size;
    }
  }

  .regular-guest {
    color: $listitem-subtitle-color;
  }

  .title-container,
  .subtitle-container {
    @include media-breakpoint-down("sm") {
      flex-direction: column;
      .localina-label.has-icon {
        flex-direction: row;
        justify-content: flex-start;
      }
      .localina-label.info:not(.has-icon) {
        margin-left: $phone-email-icon-size + $base-padding;
      }
    }
  }
}
