.dnd-preview-container {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  background: transparent;
  height: 100%;

  .item {
    max-width: 250px;
    background: transparent;

    .reservation-touch-dnd-preview {
      padding: $base-padding;
      background: rgba(255, 255, 255, 0.6);

      .reservation-touch-dnd-preview-content {
        .no-wrap {
          white-space: nowrap;
        }

        .localina-label {
          color: $message-subtitle-color;
        }
      }
    }
  }
}
