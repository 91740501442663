@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto+Slab:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.localina-rich-text-editor-container {
  display: flex;
  flex-direction: column;
  //gap: $base-padding*2;

  .editor-content {
    height: 100%;
    overflow: hidden;

    .tiptap.ProseMirror {
      height: 100%;
      border: 1px solid black;
      padding: $base-padding;
      box-sizing: border-box;
      overflow-y: auto;
    }
  }

  .toolbar {
    padding: $base-padding 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .text-color-button-container {
      display: inline-flex;
    }

    .select-field {
      margin-right: $base-margin;
    }

    .MuiToggleButtonGroup-grouped {
      padding: $base-margin;
      border: 0;
      border-radius: $border-radius;
    }

    .MuiDivider-root.MuiDivider-vertical {
      margin: $base-margin;
    }

    .MuiSelect-select {
      padding: $base-padding $base-padding * 4 $base-padding $base-padding;
      min-width: 100px;
    }
  }
}
