button.table-plan-reservations-switch-button {
  padding: 0;
  min-width: 0;
  width: auto;

  cursor: pointer;
  position: absolute;
  z-index: 2;
  left: -$base-padding + 3px;
  top: $base-padding * 2 +3px;

  border-radius: $switch-table-plan-reservation-icon-size / 2;
  overflow: hidden;

  &:hover {
    background-color: #709faf;
  }

  background-color: #709faf;
  filter: drop-shadow(0px 0px 0.5px gray);

  &:hover {
    filter: drop-shadow(0px 0px 1px gray);
  }

  @include media-breakpoint-down("lg") {
    left: 3px;
  }

  a {
    text-decoration: none;
    text-transform: none;

    .wrapper {
      display: flex;
      align-items: center;

      svg {
        width: $switch-table-plan-reservation-icon-size;
        height: $switch-table-plan-reservation-icon-size;
        margin: -3px -4px -4px -3px;
      }

      .localina-label.button-label {
        color: white;

        p {
          margin: 0 $base-margin 0 $base-margin/2;
          white-space: nowrap;
        }
      }
    }
  }

  .button-label {
    display: flex;
    justify-content: center;
    overflow: hidden;

    max-width: 0;
    width: auto;
    transition: max-width 0.4s;
    transition-timing-function: ease-in-out;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover .button-label {
      max-width: 300px;
    }
  }
}
