.restaurant-info {
  & > .text {
    padding: #{$base-padding} 0 #{$base-padding + $base-padding / 2};
  }

  .MuiGrid-container {
    margin: 0 -#{$base-margin};
    width: calc(100% + #{$base-margin * 2});

    .MuiGrid-item {
      padding: 0 #{$base-margin};
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-bottom: 3 * $base-padding;

    .cancel-notice {
      flex-basis: 15%;
    }

    .localina-inputfield {
      padding-bottom: 0;
    }
  }
}
