.guestsimport-view {
  .upload-container {
    border: $border-size dashed #cccccc;

    &__content {
      padding: #{$base-padding * 3} #{$base-padding + $base-padding / 2};
      text-align: right;

      .localina-label {
        display: flex;
        justify-content: center;
        text-align: center;
        padding-bottom: $base-padding * 3;

        &:first-child {
          padding-bottom: $base-padding;
        }
      }

      .localina-iconbutton {
        width: $icon-size-big;
        height: $icon-size-big;
        border: $border-size solid $border-color;
        border-radius: $border-radius;
        background-color: inherit;
      }
    }
  }

  .preview-info {
    padding-top: #{$base-padding * 9};
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    & > .localina-label {
      &:first-child {
        padding-bottom: $base-padding * 3;
      }
    }

    .MuiGrid-container {
      padding-bottom: $base-padding * 3;

      .MuiGrid-item {
        padding: 0 $base-padding;

        &:nth-child(odd) {
          .localina-label {
            justify-content: flex-end;
          }
        }
      }
    }
  }
}
