.availability {
  .localina-list {
    height: calc(100% - #{$base-padding * 6});
    padding: #{$base-padding * 3} 0;
    overflow: auto;
  }

  .list-item__specialopeninghour__subtitle {
    display: flex;
    align-items: baseline;
    gap: $base-padding;

    & div:first-child {
      max-width: calc(100% - 80px - #{$base-padding * 2});

      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    & div:last-child {
      min-width: 80px;
    }
  }
}
