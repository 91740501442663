.localina-iconbutton.MuiIconButton-root {
  height: $iconbutton-size;
  width: $iconbutton-size;
  background-color: $iconbutton-background-color;

  .icon path {
    fill: $iconbutton-color;
  }

  &.Mui-disabled {
    background-color: $iconbutton-disabled-background-color;

    .icon path {
      fill: $iconbutton-disabled-color;
    }
  }
}
