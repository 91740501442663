$reservations-header-height: 144px;
$reservations-footer-height: 46px;
$reservations-switch-text-size: 14px;

.reservations {
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  overflow: visible;
  box-shadow: none !important;

  @include media-breakpoint-up("md") {
    margin: 0 0 0 0;
  }

  .reservations-header {
    background-color: white;
    padding-top: $base-padding;
    position: relative;

    @include media-breakpoint-up("sm") {
      padding-top: $base-padding * 3;
    }

    &__content {
      display: flex;
      justify-content: space-between;
      margin-left: $base-margin * 3;

      align-items: flex-end;
      flex-direction: column;
      gap: $base-padding;

      @include media-breakpoint-up("sm") {
        align-items: flex-start;
        flex-direction: row;
        gap: 0;
      }

      .daily-note-wrapper {
        align-self: stretch;
        display: flex;
        gap: $base-padding * 3;
        flex: 1;

        @include media-breakpoint-up("sm") {
          align-self: unset;

          .notifications-button-wrapper-sm {
            display: none;
          }
        }

        .restaurant_daily_note_wrapper {
          flex: 1;
          min-width: 100px;
          @include media-breakpoint-up("sm") {
            max-width: 400px;
            min-width: 200px;
          }

          &.highlight-border {
            .localina-inputfield {
              background: white;
              position: relative;

              & .MuiInputBase-root {
                background-color: rgba(255, 255, 255, 0.9);
              }

              & label.MuiInputLabel-root {
                width: auto;
                background: rgba(255, 255, 255, 0.8);
                backdrop-filter: blur(3px);
                left: -5px;
                padding: 0 7.5px 0 7.5px;
              }
            }

            $color1: $orange;
            $color2: $border-color;
            $color3: $petrol-light;
            $color4: $petrol-dark;

            &.animated-highlight {
              .localina-inputfield:before {
                background-size: 400%;
              }
            }

            .localina-inputfield:before {
              content: "";
              position: absolute;
              left: -2px;
              top: -2px;
              background: linear-gradient(45deg, $color1, $color2, $color3, $color4);
              width: calc(100% + 4px);
              height: calc(100% + 4px);
              animation: steam 10s linear infinite;
              filter: blur(5px);
            }

            @keyframes steam {
              0% {
                background-position: 0 0;
              }
              100% {
                background-position: 400% 0;
              }
            }
          }

          .localina-inputfield {
            padding-bottom: 0;

            .MuiInputBase-root.MuiInputBase-multiline {
              padding: 5px 0;

              .MuiInputBase-input {
                padding-top: 0;
              }
            }
          }
        }
      }

      .reservations-date {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;

        & > .icon {
          margin: 0 #{$base-margin};
        }

        .localina-button {
          width: auto;
        }

        .localina-datefield {
          padding-bottom: 0;
          max-width: 150px;
          min-width: 58px;
        }

        @include media-breakpoint-down("sm") {
          justify-content: flex-start;
        }
      }

      .localina-iconbutton {
        background-color: $secondary-button-background-color;
        border: $border-size solid $border-color;
        border-radius: $border-radius;

        .icon {
          display: flex;

          &.notifications-available path {
            fill: $notification-color;
          }
        }
      }
    }
  }

  .button-group-style {
    overflow: auto;
    background-color: white;
  }

  .reservations-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .reservations-collapsible-filters {
      background: white;
      margin-bottom: $base-margin;
      padding-bottom: $base-padding * 3;
      position: relative;

      border-bottom-left-radius: $border-radius * 2;
      border-bottom-right-radius: $border-radius * 2;
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);

      .localina-collapse-button {
        z-index: 1;
        border-radius: 50%;
        position: absolute;
        padding: $base-padding/2;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        background-color: white;
        box-shadow: inset 0 3px 4px 0 rgba(0, 0, 0, 0.25);
        cursor: pointer;

        .icon svg {
          height: $icon-size-medium;
          width: $icon-size-medium;
        }
      }

      .localina-tabs {
        box-shadow: 0 6px 4px -3px $shadow-color;
        margin-bottom: $base-padding;
        padding: 0 $base-padding * 3 0;

        @include media-breakpoint-up("sm") {
          padding: 0 $base-padding * 3 0;
        }

        .unassigned-shifts {
          background-color: $page-title-background-color;

          &:not(.Mui-selected) {
            color: white;
            background-color: $error-color;
          }
        }
      }
    }

    &__content {
      padding-top: $base-padding * 3;
      padding-bottom: #{$fabbutton-size - $base-padding * 2};
    }

    .emptyShiftWrapper {
      padding: #{2 * $base-padding};

      .clear-filter-link {
        text-decoration: underline;
        color: blue;
        cursor: pointer;
      }
    }

    .reservations-body__bottom-toolbar {
      display: flex;
      padding: $base-padding $base-padding + $base-margin 0;
      gap: $base-margin;
      justify-content: space-between;

      align-items: flex-end;
      flex-direction: column;

      @include media-breakpoint-up("sm") {
        flex-direction: row;
        padding: $base-padding * 2 $base-padding + $base-margin 0;
        gap: $base-margin * 2;
      }

      &__switch {
        display: flex;
        gap: $base-margin * 2;
        align-items: center;

        .localina-switch {
          width: auto;

          @include media-breakpoint-up("xs") {
            &.has-label .MuiTypography-root {
              white-space: nowrap;
            }
          }
        }

        .MuiFormControlLabel-label {
          font-size: $reservations-switch-text-size;

          @include media-breakpoint-up("sm") {
            flex-grow: 0;
          }
        }
      }

      @include media-breakpoint-down("xs") {
        gap: $base-padding;
        padding: $base-padding $base-padding 0;
        &__switch {
          justify-content: space-between;
          width: 100%;
          gap: $base-padding;

          label {
            width: auto;
          }
        }
        &__button {
          button {
            height: 48px;
            min-height: 48px;
            width: 48px;
            min-width: 48px;
            padding: 0;
          }

          p {
            display: none;
          }
        }
      }

      &__text-filter {
        width: 100%;

        @include media-breakpoint-up("sm") {
          width: 500px;
        }

        .localina-inputfield {
          padding: 0;

          .MuiInputBase-root {
            background-color: #e7eff1;
          }
        }
      }
    }

    &__button {
      width: 100px;
      //margin: 0 $base-margin;
    }

    .swipeable-views {
      flex: 1;
      border-top-left-radius: $border-radius * 2;
      border-top-right-radius: $border-radius * 2;

      & > div {
        height: 100%;
      }
    }
  }

  .localina-switch.has-label {
    padding: 0;
  }

  .reservations-footer {
    padding: #{$base-padding * 2} #{$base-padding * 7};
    background-color: $reservations-header-color;
    z-index: 1;
    box-shadow: 0 -#{$shadow-size} $shadow-size $shadow-color;
    display: flex;

    .localina-label:nth-child(1) {
      margin-right: $base-padding * 6;
    }
  }

  .localina-speeddial {
    & .MuiSpeedDialAction-staticTooltip {
      $scale-fab-buttons: 1.3;
      $scale-tooltip-label: 0.6;

      & .MuiSpeedDialAction-staticTooltipLabel {
        height: calc($speeddial-height * $scale-tooltip-label);
        inset: auto;
        right: 100%;
        margin: 8px 0 8px;
        display: flex;
        justify-content: center;
      }

      & .MuiFab-root {
        height: calc($speeddial-height * $scale-fab-buttons);
        width: calc($speeddial-width * $scale-fab-buttons);
      }
    }

    .MuiSpeedDial-fab {
      background-color: $fabbutton-background-color;
    }
  }

  .reservations-body__content {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    background-color: transparent;
  }

  .reservations-header {
    padding: $base-padding * 3 $base-padding * 3 $base-padding * 2;

    @include media-breakpoint-down("sm") {
      padding: $base-padding $base-padding * 3 0;
    }
  }

  .unassigned-reservations-info {
    margin: 10px 0;
    background-color: white;
    color: $error-color;
    border: 1px solid $error-color;
    font-weight: bold;
    height: auto;
    padding: 8px 0;

    .MuiChip-label {
      white-space: normal;
    }
  }
}

.date-picker-dialog .restaurant-closed-day {
  color: lightgray;
}
