.welcome-modal {
  *:focus {
    outline: none;
  }

  &__container {
    &.MuiPaper-root {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: $welcome-modal-max-width;
      width: calc(100% - #{$base-margin} * 2);
      max-height: $welcome-modal-max-height;
      height: calc(100% - #{$base-margin} * 2);
      background-color: $welcome-modal-background-color;
    }
  }

  &__close-icon {
    position: absolute;
    right: $welcome-modal-close-icon-right;
    top: $welcome-modal-close-icon-top;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: $welcome-modal-background-size;
    background-position: $welcome-modal-background-position;
    background-repeat: no-repeat;
    overflow-y: auto;

    *:focus {
      outline: none;
    }
  }

  &__title {
    margin: (7 * $base-margin) (3 * $base-margin) (2 * $base-margin) (3 * $base-margin);
    color: #709faf;
  }

  &__text-block {
    margin: 0px (3 * $base-margin) (2 * $base-margin) (3 * $base-margin);
  }

  &__signature-text {
    margin: (4 * $base-margin) (3 * $base-margin) (2 * $base-margin) (3 * $base-margin);
    text-align: right;
  }

  &__list {
    padding-left: 1em;
    margin: 0;
  }

  &__highlight {
    color: $message-subtitle-color;
  }
}
