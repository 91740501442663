.restaurant-wizard {
  position: relative;

  .title-wrapper {
    background-color: #f0f5f7;
    color: #005e6e;
    display: flex;
    justify-content: center;
    padding: $base-padding * 4 0 $base-padding * 2;
  }

  &__header {
    display: flex;
    padding-bottom: $base-padding * 3;

    .icon path {
      fill: black;
    }
  }

  &__divider {
    margin-bottom: $base-padding * 3;
  }

  &__page {
    & > .buttons {
      margin-top: $base-margin * 4;
    }
  }

  &.start {
    .title {
      padding-bottom: $base-padding * 2;
    }

    .text {
      padding-bottom: $base-padding * 3;
    }

    .buttons {
      justify-content: center;
    }
  }

  &.platforms {
    .platforms-content-container {
      height: 100%;
    }
  }

  &.availability {
    .localina-list {
      margin: 0 -#{$base-padding * 4};
      width: calc(100% + #{$base-padding * 8});
      padding-bottom: $base-padding * 3;
    }

    .localina-speeddial {
      position: absolute;
      bottom: $base-margin * 4 + 48px + $base-padding * 3;
    }

    .availability-content-container {
      width: 100%;
      height: 100%;
    }
  }

  &.done {
    .text {
      padding-bottom: $base-padding * 3;
    }
  }
}
