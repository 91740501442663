.support-user {
  #user {
    height: calc(100% - #{$base-padding * 6});
    padding: #{$base-padding * 3} 0;
    overflow: auto;

    .form {
      padding: 0 #{$base-padding * 3};
    }

    .title {
      color: $message-subtitle-color;
      padding-bottom: $base-padding * 3;
    }
  }
}
