.edit-restaurant-drawer {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  .edit-restaurant-account {
    .account-number {
      margin-bottom: $base-margin;
    }

    .list-item-edit-restaurant-plan {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: $base-margin;

      .left-icon {
        margin-right: $base-margin / 2;
        font-size: 20px;
        color: #003a5c;
      }

      .right-icon {
      }

      .name {
        flex: 1;
        color: #709faf;
      }
    }
  }

  .clickable {
    cursor: pointer;
  }
}

.edit-single-account-restaurant {
  .name-title {
    margin-top: $base-padding * 2;
  }

  .restaurant-name {
    margin-top: $base-padding / 2;
  }

  .version-title {
    margin-top: $base-padding * 4;
    margin-bottom: $base-padding + ($base-padding / 2);
  }

  .delete-db-buttons {
    display: flex;
    flex-direction: column;
    gap: $base-margin;

    .localina-button.MuiButton-root.primary {
      background-color: #709faf;
    }
  }
}

.edit-restaurant-plan-button {
}
