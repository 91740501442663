$app-menu-width: 310px;
$page-max-width: 1024px;
$page-max-width-lg: 80%;
$page-max-width-xl: 1536px;
$auth-page-max-width: 400px;

$app-menu-title-color: #005e6e;
$app-menu-category-color: #709faf;
$app-menu-category-item-color: #003a5c;
$app-menu-category-item-selected-color: #f0f5f7;
$app-container-margin-left: 12px;

$light-blue-color: #f0f5f7;
$sky-blue-color: #709faf;
$table-object-blue-color: #009fba;
$table-object-red: #ce5f4c;
$table-object-gray: #686767;
$table-object-green: #7fec48;
$floor-object-color: #e6ceaa;
$wall-object-color: #f7f7f7;
$table-info-background: #00687a;
$table-info-background-red: #872919;
$table-info-background-gray: #eaeaea;
$table-info-background-green: #44942e;
$table-info-color: #e6eff1;
$table-info-color-red: #ba1b00;
$table-info-color-green: #42b707;
$table-info-color-gray: #494848;
$table-name-color-green: #32881b;

$icon-default-color: #003a5c;

$reservations-header-color: #bfd4db;
$reservations-subheader-color: #f0f5f7;
$reservations-guest-color: #005e6e;
$reservations-create-icon-color: #b4788b;
$reservations-requested-background-color: #fff3ca;

$page-title-background-color: #f0f5f7;
$page-title-color: #005e6e;
$page-title-height: 100px;
$page-actions-background-color: #bfd4db;
$page-actions-height: 72px;

$guest-reservations-counter-color: #ffffff;
$guest-reservations-counter-background-color: #338fb8;
$guest-reservations-counter-border-color: #b2d5e4;
$guest-details-title-color: #709faf;

$message-subtitle-color: #709faf;
$message-datefield-max-width: 400px;

$autocomplete-background-color: #ffffff;
$autocomplete-max-height: 150px;
$autocomplete-item-label-color: #709faf;
$autocomplete-item-label-highlight-color: #005e6e;
$autocomplete-item-info-color: #808080;

$welcome-modal-max-width: 800px;
$welcome-modal-background-color: #f0f5f7;
$welcome-modal-background-size: 500px;
$welcome-modal-background-position: left -200px bottom -220px;
$welcome-modal-max-height: 500px;
$welcome-modal-close-icon-top: 20px;
$welcome-modal-close-icon-right: 20px;

$switch-table-plan-reservation-icon-size: 40px;
$table-plan-controller-height: 40px;
