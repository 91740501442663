.area-creation-details {
  & > .localina-label {
    margin-left: $icon-size + $base-margin;
    padding-bottom: #{$base-padding + $base-padding / 2};
  }

  .localina-buttongroup {
    padding-bottom: $base-padding * 4;
  }

  .localina-checkbox {
    padding-bottom: $base-padding * 3;
  }

  .localina-selectfield {
    .MuiSelect-iconOpen {
      transform: none;
    }
  }
}
