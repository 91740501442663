.review-pending-reservation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $base-margin * 3;
  padding: $base-padding * 3 0;

  @include media-breakpoint-up("sm") {
    padding: $base-padding * 3;
  }

  & > .MuiPaper-root {
    padding: $base-padding * 2 $base-padding;

    @include media-breakpoint-up("sm") {
      padding: $base-padding * 2;
    }

    width: 400px;
    max-width: 80%;

    .buttons {
      margin-top: $base-margin * 8;
      display: flex;
    }

    .review-result-information {
      margin-top: $base-margin * 8;
      width: 100%;
      box-sizing: border-box;
    }
  }
}
