.table-objects-view-section-title {
  display: flex;
  flex-direction: row;
  color: $sky-blue-color;

  .icon svg {
    color: $sky-blue-color !important;
  }
}

.table-objects-view-section-hidden {
  display: none;
}

.table-objects-view-section-title {
  color: $sky-blue-color !important;
  margin-bottom: $base-margin * 2;
}

.table-objects-view-row {
  display: flex;
  flex-direction: row;
  margin-bottom: $base-margin * 2;
}

.table {
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
}

.drag-container {
  background: transparent;
}

.small-table {
  width: 64px;
  height: 64px;
  background-color: $table-object-blue-color;
  margin-right: $base-margin * 3;
}

.big-table {
  width: 128px;
  height: 64px;
  background-color: $table-object-blue-color;
}

.small-table-rounded {
  border-radius: 50%;
  background-color: $table-object-blue-color;
}

.big-table-rounded {
  border-radius: 32px;
  background-color: $table-object-blue-color;
}

.buildings-obj {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
}

.floor {
  margin-right: $base-margin * 3;
  background-color: $floor-object-color;
}

.wall {
  background-color: $wall-object-color;
}

.plant-wrapper {
  margin-right: $base-margin * 3;
}

.plant {
  width: 64px;
  height: 64px;
}
