$accordion-summary-color: #d4e2e7;
$accordion-details-color: #f0f5f7;

.area-accordion-item {
  margin-bottom: $base-padding * 2;

  .accordion-style {
    box-shadow: none;
    position: inherit;
    margin-top: $base-padding * 2;
  }

  .accordion-summary-style {
    background-color: #{$accordion-summary-color};
    border-radius: $base-padding * 2;

    .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
      transform: rotate(0deg);
    }
  }

  .accordion-summary-style.Mui-expanded {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    min-height: $base-padding * 6;
    max-height: $base-padding * 6;
  }

  .accordion-details-style {
    background-color: #{$accordion-details-color};
    border-bottom-right-radius: $base-padding * 2;
    border-bottom-left-radius: $base-padding * 2;
    padding-bottom: 0;
    padding-top: $base-padding * 3;
    @include media-breakpoint-down("xs") {
      padding-left: $base-padding * 1.5;
      padding-right: $base-padding * 1.5;
    }
  }

  .capacity-percentage-label {
    padding-bottom: $base-padding;
  }

  .accordion-area-list {
    margin-bottom: $base-padding * 2;
  }

  .typography-margin-left {
    margin-left: $base-padding * 2;
  }
}
