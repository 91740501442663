.localina-textfield {
  .MuiInputBase-root {
    height: $inputfield-height;
  }

  .textfield__clear-button {
    cursor: pointer;
    color: #4a4a4a;

    &:hover {
      color: rgba(74, 74, 74, 0.6);
    }
  }
}

.localina-textarea {
  &.has-icon {
    .field-container {
      align-items: flex-start;

      .icon {
        padding-top: $base-padding * 2;
      }
    }
  }
}
