.localina-switch {
  display: flex;
  align-items: center;
  justify-content: center;

  &.has-label {
    padding-bottom: $base-padding * 3;
    width: 100%;

    .MuiFormControlLabel-root {
      margin-left: 0;
      width: 100%;

      .MuiFormControlLabel-label {
        flex-grow: 1;
      }
    }
  }

  &.has-icon {
    .icon {
      height: $icon-size;
      width: $icon-size;
    }

    .MuiFormControlLabel-root {
      margin-left: $base-margin;
    }
  }

  &.use-icon {
    .MuiFormControlLabel-root {
      margin-left: $icon-size + $base-margin;
    }
  }

  .MuiSwitch-track {
    background-color: $switch-background-color;
  }

  .MuiSwitch-thumb {
    background-color: $switch-color;
  }

  .MuiSwitch-switchBase.Mui-checked {
    .MuiSwitch-thumb {
      background-color: $switch-checked-color;
    }

    & + .MuiSwitch-track {
      background-color: $switch-checked-background-color;
    }
  }
}
