.table-plans {
  &-list-item {
    padding-top: $base-padding * 1.5;

    .localina-listitem {
      border: 0;
      padding: $base-padding * 1.5 $base-padding * 3;
    }

    .localina-label {
      color: $listitem-subtitle-color;
    }
  }

  &-area-list-item {
    .localina-listitem {
      border-top: $border-size solid $border-color;
    }

    .localina-label {
      color: black;
    }
  }

  &-add-button {
    background-color: $speeddial-background-color;
  }
}
