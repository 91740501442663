.localina-speeddial {
  bottom: $speeddial-bottom;
  right: $speeddial-right;
  margin-right: $speeddial-right;

  width: $speeddial-width;

  &.position-fixed {
    position: fixed;
  }

  .MuiFab-root {
    &,
    &:hover {
      background-color: $speeddial-background-color;
    }

    .icon path {
      fill: $speeddial-action-color;
    }
  }

  .MuiSpeedDialAction-staticTooltip {
    .MuiFab-root {
      box-shadow: 0 $shadow-size $shadow-size $shadow-color;

      &,
      &:hover {
        background-color: $speeddial-action-background-color;
      }

      &.Mui-disabled {
        background-color: $speeddial-action-disabled-background-color;
      }

      .icon {
        svg {
          height: $icon-size;
          width: $icon-size;
          fill: $speeddial-action-color;
        }

        path {
          fill: $speeddial-action-color;
        }
      }
    }

    .MuiSpeedDialAction-staticTooltipLabel {
      background-color: $speeddial-action-background-color;
      color: $speeddial-action-color;
      padding: $base-padding #{$base-padding * 2};
      border-radius: $border-radius;
      box-shadow: 0 #{$shadow-size / 2} $shadow-size $shadow-color;
      min-width: #{$speeddial-min-width};
      inset: #{$speeddial-inset} #{$speeddial-inset} auto auto;
      margin-right: #{$speeddial-margin-right};
      cursor: pointer;

      &.Mui-disabled {
        background-color: $speeddial-action-disabled-background-color;
      }
    }
  }
}
