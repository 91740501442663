.reservation-status {
  display: flex;

  .localina-label {
    padding-right: $base-padding * 2;
  }
}

.select-reservation-status {
  .localina-buttongroup {
    .localina-label {
      text-transform: initial;

      .MuiTypography-root {
        white-space: nowrap;
      }
    }
  }
}
