.reservations-shiftgroup {
  border-radius: 8px;
  padding: 0 $base-padding/2 $base-padding/2;
  margin-bottom: $base-margin/2;
  background-color: $reservations-header-color;

  $time-column-width: 65px;
  $time-column-width-down-xs: 58px;

  &.extra-small {
    .reservations-shiftgroup__header .localina-label.title {
      width: $time-column-width-down-xs;
    }

    .reservations-shiftgroup__body {
      .reservations-shiftgroup__time-group {
        &__color-indicator {
          width: $base-padding;
        }

        &__reservation {
          //min-height needed to be set because of the status action icon's absolute position
          min-height: 74px;
          flex-direction: column;
          padding: $base-padding/2 $base-padding/2 $base-padding/2 $base-padding;

          .reservation__guest__guest-status {
            order: 1;
          }

          .date-created-and-type {
            order: 2;
            margin-left: auto;
          }

          .status-action-icon-absolute-extra-small {
            position: absolute;
            right: $base-padding/2;
            bottom: $base-padding/2;
            height: 50px;

            .localina-checkbox {
              padding-bottom: 0;

              span {
                width: 50px;
                height: 50px;
              }
            }
          }

          .reservation__info__icon {
            min-width: 20px;
            margin: 0;
          }

          .reservation__tablenumber {
            gap: $base-padding;
          }

          .tables-and-area-codes-container {
            display: flex;
            justify-content: flex-end;
            flex-direction: row-reverse;

            .reservation__area__codesList > div {
              &:not(:first-child) {
                display: none;
              }

              &:first-child:not(:only-child) p:after {
                content: " ...";
                position: absolute;
              }
            }
          }

          .reservation__details {
            gap: $base-padding;

            .reservation__info {
              order: 2;
            }

            .reservation__info__wrapper {
              justify-content: space-between;
              flex-direction: row;

              .icon svg {
                width: 20px;
                height: 20px;
              }

              .reservation__guest__company-wrapper {
                gap: 0;
              }
            }
          }

          .reservation__guest__name-wrapper {
            flex-direction: row;
            justify-content: flex-start;
          }
        }
      }

      .reservations-shiftgroup__time-group__header {
        padding: 0 $base-padding/2;
      }
    }
  }

  &.small {
    .reservations-shiftgroup {
      &__body {
        .reservations-shiftgroup__time-group {
          &__reservation {
            .date-created-and-type {
              gap: 0;
              flex-direction: column-reverse;
              padding-left: $base-padding;
            }

            .reservation-origin-wrapper {
              display: flex;
            }

            .tables-and-area-codes-container {
              display: flex;
              flex-direction: column;
              height: 100%;
              justify-content: center;

              .reservation__area__codesList > div {
                &:not(:first-child) {
                  display: none;
                }

                &:first-child:not(:only-child) p:after {
                  content: " ...";
                  position: absolute;
                }
              }
            }

            .reservation__guest {
              min-width: 160px;
              width: 160px;
            }
          }
        }
      }
    }
  }

  &.medium {
    .reservations-shiftgroup {
      &__body {
        .reservations-shiftgroup__time-group {
          &__reservation {
            .date-created-and-type {
              gap: 0;
              flex-direction: column-reverse;
              padding-left: $base-padding;
            }

            .reservation__details {
              .reservation__info__wrapper {
                justify-content: flex-end;
                flex-direction: row-reverse;
              }
            }

            .reservation__guest {
              min-width: 160px;
              width: 160px;
            }
          }
        }
      }
    }
  }

  &.large {
    .reservations-shiftgroup {
      &__body {
        .reservations-shiftgroup__time-group {
          &__reservation {
            .reservation__guest {
              min-width: 200px;
            }
          }
        }
      }
    }
  }

  &.extra-large {
    .reservations-shiftgroup {
      &__body {
        .reservations-shiftgroup__time-group {
          &__reservation {
            .reservation__guest {
              min-width: 30%;
            }

            .reservation__details {
              flex-direction: row;
              width: 700px;

              .reservation__info {
                gap: $base-padding;
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }
  }

  &__header {
    position: sticky;
    top: 0;
    z-index: 1;
    padding-right: #{$base-padding * 2};
    display: flex;
    justify-content: space-between;

    background-color: $reservations-header-color;

    .localina-label.title {
      //shift name wrapper

      justify-content: stretch;
      width: $time-column-width;

      white-space: nowrap;

      & [role="tooltip"] {
        white-space: normal;
      }

      & path {
        fill: black;
      }

      & p {
        width: 100%;
        text-align: center;
      }
    }

    &__info {
      //number of guests wrapper
      display: flex;
      gap: $base-padding;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .reservations-shiftgroup__time-group {
      display: flex;
      overflow: hidden;
      border-radius: $border-radius;

      &__header {
        padding: 0 $base-padding * 1.5;
        background-color: $reservations-subheader-color;
        border-right: $border-size solid $icon-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        width: $time-column-width;

        .localina-label.info {
          p {
            white-space: nowrap;
            font-size: 11px;
            margin-left: 0;
          }

          .icon.small {
            height: 20px;
            width: 20px;
          }
        }

        &__action {
          min-width: $icon-size;

          .icon path {
            fill: $reservations-create-icon-color;
          }
        }
      }

      &__body {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
        background-color: white;

        .localina-label {
          color: $reservations-guest-color;
        }

        & > div:not(:last-child) {
          border-bottom: $border-size solid $border-color;
        }
      }

      &__color-indicator {
        position: absolute;
        width: $base-padding * 1.5;
        height: 100%;
        left: 0;
        top: 0;
      }

      &__reservation {
        position: relative;
        height: 100%;
        padding: $base-padding/2 $base-padding * 1.5;
        display: flex;
        justify-content: space-between;
        background-color: white;
        min-height: 65px - $base-padding * 4 + $border-size;

        .tables-and-area-codes-container {
          display: flex;

          .localina-tooltip__wrapper {
            display: flex;
          }
        }

        .reservation__info__icon {
          display: flex;
          width: 26px;
          margin: 0 $base-padding;

          svg path {
            fill: #709faf;
          }

          .reservation-guest-additional-info-icon-container {
            display: flex;
            align-items: center;

            svg {
              width: 26px;
              height: 26px;
            }

            &:not(.no-additional-info) path {
              fill: $error-color;
            }
          }
        }

        &.vip {
          .reservation__guest__name-wrapper {
            p {
              font-weight: bold;
            }
          }
        }

        &.blacklist {
          .reservation__guest__name-wrapper {
            p {
              color: $error-color;
              font-weight: bold;
            }
          }
        }

        .reservation {
          &__guest {
            cursor: pointer;
            display: flex;
            overflow: hidden;

            align-items: center;
            gap: 10px;
            justify-content: flex-start;

            &__guest-status {
              width: 24px;
            }

            &__name-wrapper {
              display: flex;
              gap: $base-padding;

              justify-content: center;
              flex-direction: column;

              .d-xs {
                @include media-breakpoint-up("xs") {
                  display: none;
                }
              }

              .d-xs-none {
                @include media-breakpoint-down("xs") {
                  display: none;
                }
              }

              & p {
                white-space: nowrap;
              }
            }
          }

          &__details {
            display: flex;
            flex-grow: 1;
            overflow: hidden;
            //flex-direction: column;
            justify-content: center;
            align-items: stretch;

            .reservation__info__wrapper {
              display: flex;
              flex-grow: 1;
              min-width: 0;
              justify-content: flex-end;
              cursor: pointer;

              .reservation__guest__company-wrapper {
                display: flex;
                justify-content: flex-start;
                gap: $base-padding;
                flex-grow: 1;
                min-width: 0;
                overflow: hidden;

                $min-width-to-hide-company: 80px;
                max-width: calc((100% - #{$min-width-to-hide-company}) * 9999);

                .d-xs {
                  @include media-breakpoint-up("xs") {
                    display: none;
                  }
                }

                .d-xs-none {
                  @include media-breakpoint-down("xs") {
                    display: none;
                  }
                }

                .localina-label.text,
                .localina-label.info {
                  min-width: 0;

                  p {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }
                }
              }
            }

            .reservation__info {
              flex: 0;
              gap: $base-padding/2;
              justify-content: space-between;

              .localina-textfield,
              .localina-checkbox {
                padding-bottom: 0;
              }
            }
          }

          &__info,
          &__participants,
          &__tablenumber,
          &__area,
          &__status {
            display: flex;
          }

          &__participants,
          &__tablenumber,
          &__area {
            justify-content: flex-start;

            .icon {
              padding-right: $base-padding;
              @include media-breakpoint-down("md") {
                padding-right: 0;
              }
            }

            .localina-label {
              color: $reservations-guest-color;
            }
          }

          &__participants {
            $min-width: 50px;
            min-width: $min-width;
            @include media-breakpoint-down("md") {
              min-width: $min-width - $base-padding;
            }
          }

          &__tablenumber {
            $min-width: 102px;
            min-width: $min-width;
            @include media-breakpoint-down("md") {
              min-width: $min-width - $base-padding;
            }

            .localina-label {
              color: $reservations-guest-color;
            }

            .MuiTypography-root {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              max-width: 70px;
            }

            .icon svg {
              width: $icon-size;
              height: $icon-size;
            }
          }

          &__area {
            $min-width: 95px;
            min-width: $min-width;
            @include media-breakpoint-down("md") {
              min-width: $min-width - $base-padding;

              $min-width-to-hide-area: 200;
              max-width: calc((100% - #{$min-width-to-hide-area}) * 9999);
            }
            @media (max-width: 345px) {
              min-width: 0;
              .icon {
                display: none;
              }
            }
            @include media-breakpoint-down("xs") {
              overflow: hidden;
              .localina-label.text {
                overflow: hidden;

                p {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
              }
            }
            @include media-breakpoint-up("xs") {
              &.d-xs-none {
                display: none;
              }
            }
            @include media-breakpoint-down("xs") {
              &.d-none {
                display: none;
              }
            }

            &__codesList {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
            }
          }

          &__status {
            &.d-xs-none {
              @include media-breakpoint-down("xs") {
                display: none;
              }
            }

            &.d-xs-block {
              @include media-breakpoint-up("xs") {
                display: none;
              }
            }

            &.d-sm-none {
              min-width: $checkbox-size;
              justify-content: center;

              @include media-breakpoint-down("sm") {
                display: none;
              }

              .localina-checkbox .MuiCheckbox-root {
                border: none;

                div.icon.big {
                  border: 1px solid #cfd4d9;
                  border-radius: 4px;
                  padding: 3px;
                }
              }
            }

            &:not(.d-sm-none) {
              @include media-breakpoint-up("sm") {
                display: none;
              }
            }
          }
        }

        .reservation__status {
          &.d-sm-none {
            min-width: $checkbox-size;
            justify-content: center;

            @include media-breakpoint-down("sm") {
              display: none;
            }

            .localina-checkbox .MuiCheckbox-root {
              border: none;

              div.icon.big {
                border: 1px solid #cfd4d9;
                border-radius: 4px;
                padding: 3px;
              }
            }

            .localina-checkbox {
              padding-bottom: 0;
            }
          }
        }

        .date-created-and-type {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: $base-padding;
        }
      }

      &__cancelled {
        opacity: 0.55;
        background-color: $reservations-header-color;
      }

      &__requested {
        background-color: $reservations-requested-background-color;
      }

      &:last-child {
        .reservations-group__time-group__reservation {
          border-bottom: $border-size solid $border-color;
        }
      }

      .reservations-shiftgroup__time-group__reservation:only-child {
        height: 100%;
      }
    }
  }

  .area-code-and-status-wrapper {
    display: flex;
    gap: $base-padding;
  }

  &.unassigned-shift {
    background-color: $error-color;

    & .reservations-shiftgroup__header {
      color: white;
      background-color: $error-color;

      & path {
        fill: white;
      }
    }

    & .reservations-shiftgroup__time-group__body {
      background-color: $reservations-header-color;
    }
  }

  &.display-none {
    visibility: hidden;
    height: 0;
    padding: 0;
    margin: 0;
  }

  &:not(.extra-small) {
    .d-xs-block,
    .d-xs {
      display: none;
    }
  }

  &.extra-small {
    .d-xs-none {
      display: none;
    }
  }

  .restaurant-comments {
    display: none;
  }

  &:not(.display-comments) {
    .reservation__info__icon.allergies-only {
      display: none;
    }
  }

  .reservation__guest__name-wrapper {
    overflow: hidden;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.display-comments {
    &.dense-table {
      .reservations-shiftgroup__body .reservations-shiftgroup__time-group__reservation {
        padding-right: $base-padding;

        .reservation__guest {
          min-width: 150px;
          width: 150px;
          gap: 0;
        }

        .reservation__details {
          gap: $base-padding/2;

          .restaurant-comments {
            gap: $base-padding/2;

            p {
              min-width: 130px;
            }

            & .localina-label.has-icon .MuiTypography-root {
              margin-left: $base-padding/4;
            }
          }
        }

        .reservation__guest__company-wrapper {
          gap: $base-padding/4;
        }

        .reservation__participants {
          min-width: 43px;
        }

        .reservation__info__icon {
          margin: 0 $base-padding/2;
        }

        .reservation__tablenumber {
          min-width: 90px;
          width: 90px;
          margin-right: $base-padding/2;
          overflow: hidden;

          .localina-tooltip__wrapper,
          .reservation-table-numbers-container,
          .localina-label.elipsis.text {
            overflow: hidden;
          }
        }

        .reservation__area {
          min-width: 85px;
          width: 85px;
        }

        .reservation__participants,
        .reservation__tablenumber,
        .reservation__area {
          .icon {
            padding-right: $base-padding/4;
          }
        }
      }
    }

    .reservations-shiftgroup__body .reservations-shiftgroup__time-group__reservation {
      height: auto;

      .reservation__guest {
        min-width: 160px;
        width: 160px;
      }

      .reservation__info__icon:not(.allergies-only) {
        display: none;
      }

      .reservation__details {
        gap: $base-margin;
        justify-content: space-between;

        .reservation__info__wrapper {
          flex-grow: unset;
          width: 300px;
        }

        .restaurant-comments {
          display: flex;
          width: 100%;
          justify-content: space-between;
          gap: $base-margin;
          max-width: 800px;

          & > div {
            flex: 1;
          }

          p {
            max-height: 19px * 3;
            width: 100%;
            min-width: 160px;

            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    &.large {
      .date-created-and-type {
        flex-direction: column-reverse;
        gap: 0;
      }
    }
  }

  .walkin {
    .restaurant-comments,
    .reservation__info__icon {
      visibility: hidden;
    }
  }
}
