.support-accounts {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  .localina-fabbutton {
    background-color: $speeddial-background-color;
  }

  #accounts {
    height: calc(100% - #{$base-padding * 3});
    padding-bottom: #{$base-padding * 3};
    overflow: auto;
  }

  .accounts-filters {
    padding: #{$base-padding * 3};
  }

  .support-accounts-row:nth-child(1) {
    border-top: 0.5px solid rgba(128, 128, 128, 0.51);
  }

  .support-accounts-row {
    padding: ($base-padding + $base-padding / 2) $base-padding * 3;
    display: flex;
    flex-direction: row;
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.51);
    align-items: center;

    .support-accounts-row-restaurant-info {
      flex: 1;
      overflow: hidden;

      .support-accounts-row-top {
        cursor: pointer;
      }
    }

    .support-accounts-row-bottom {
      display: flex;
      flex-direction: row;
      margin-top: $base-padding;
      overflow-x: auto;
      overflow-y: hidden;

      .support-accounts-bottom-row-element {
        display: flex;
        align-items: center;
        margin-right: $base-margin;
        cursor: pointer;

        label {
          cursor: pointer;
        }

        .label {
          color: #709faf;
        }
      }
    }
  }
}
