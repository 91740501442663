.guest-reservation {
  .reservation-details {
    height: calc(100% - #{$base-padding * 5});
    padding: #{$base-padding * 4} #{$base-padding * 3} #{$base-padding};
    overflow: auto;
  }

  & > .text {
    padding-bottom: $base-padding * 3;
  }
}
