.localina-button.MuiButton-root {
  height: $button-height;
  min-height: $button-height;
  font-weight: normal;
  width: 100%;

  &.primary {
    border: none;
    background-color: $primary-button-background-color;
    color: $primary-button-label-color;

    &.Mui-disabled {
      background-color: $primary-button-disabled-color;
    }
  }

  &.secondary {
    border-color: $secondary-button-border-color;
    background-color: $secondary-button-background-color;
    color: $secondary-button-label-color;
  }
}

.buttons {
  display: flex;
  height: $button-height;
  min-height: $button-height;
  width: 100%;
  justify-content: flex-end;
  gap: $base-margin * 2;

  @include media-breakpoint-down("xs") {
    gap: $base-margin;
  }

  .localina-button {
    flex-basis: 50%;
  }
}

.button {
  .localina-button {
    width: unset;
  }
}
