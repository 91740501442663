.localina-page {
  display: flex;
  flex-direction: column;

  .localina-page__header {
    .menu {
      display: flex;
      align-items: center;
      gap: $base-padding * 2;
    }

    .localina-logo {
      cursor: pointer;
    }

    .notifications-button-wrapper {
      .notifications-badge .MuiBadge-badge {
        background-color: $error-color;
        top: 4px;
        font-size: 1rem;
      }
    }

    @include media-breakpoint-down("xs") {
      .bar-button:first-child {
        overflow: hidden;

        .menu {
          overflow: hidden;

          .localina-label {
            overflow: hidden;

            p {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
      padding: 0 $base-padding;
      .menu {
        gap: $base-padding;
      }
      .localina-logo .icon svg {
        width: 90px;
        margin-left: $base-padding;
      }
      .notifications-button-wrapper {
        margin-left: $base-padding;

        button {
          margin: 0;
        }
      }
    }
  }

  main {
    .localina-page__body {
      &.app-container {
        padding: 0;
        width: 100%;
        height: 100%;
        position: relative;

        .app-container__background {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-size: 1000px;
          background-position: left -700px bottom -370px;
          background-repeat: no-repeat;

          &__overlay {
            width: 100%;
            height: 100%;
            background-color: $header-background-color;
            opacity: 0.1;
          }
        }

        .app-container__body {
          width: 100%;
          height: 100%;
          margin: 0 auto;
          display: flex;
          flex-direction: row;
          z-index: 1;

          .MuiDrawer-root {
            height: 100%;
          }

          .app-menu-tabs-vertical-collapse {
            background: white;
            margin-right: $base-margin;

            .app-menu-tabs-vertical {
              //background: white;

              button {
                padding: 0;
                min-width: 40px;
              }

              & .localina-label.text {
                writing-mode: vertical-rl;
                text-orientation: mixed;
                padding: $base-padding;
                //background: white;
              }
            }
          }

          .sticky-menu-buttons {
            display: flex;
            flex-direction: column;
            gap: $base-padding;

            & .localina-label.text {
              writing-mode: vertical-rl;
              text-orientation: upright;
              padding: $base-padding;
              background: lightgrey;
            }
          }
        }

        .body-margin {
          margin-left: #{$app-container-margin-left + $app-menu-width} !important;
        }

        .app-container__body__content {
          background-color: white;
          position: relative;
          flex: 1;
          z-index: 1300;
          width: calc(100% - #{$app-menu-width} - 2 * #{$app-container-margin-left});
          height: 100%;

          @include media-breakpoint-up("lg") {
            margin-left: $app-container-margin-left;
            padding-right: $app-container-margin-left;
          }
        }

        .is-reservation-page {
          background-color: transparent;
          margin: 0;
          padding-right: 0;

          .service-page .page-body {
            padding: 0 12px;
          }
        }
      }
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}
