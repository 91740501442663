$restaurant-stats-shift-tile-height: 168px;
$restaurant-stats-shift-tile-width: 268px;
$restaurant-stats-shift-tile-width-sm: 208px;

$restaurant-stats-chart-height: 30px;
$restaurant-stats-chart-bar-width: 100%;
$restaurant-stats-chart-bar-color: #b2d5e4;
$restaurant-stats-chart-bar-exceeded-color: #f7c0c1;
$restaurant-stats-chart-bar-border-color: #e6e6e6;
$restaurant-stats-chart-line-color: #e6ceaa;

.restaurant-stats {
  &__date {
    padding: 0 #{$base-padding * 2};
    overflow-x: hidden;

    & > .title {
      padding-bottom: #{$base-padding * 3};
    }

    &__content {
      & > .restaurant-stats__tile {
        margin-bottom: #{$base-padding * 2};
      }
    }

    &__shift-wrapper {
      overflow-x: auto;
      padding: $base-padding 0;
    }

    &__shift {
      display: inline-flex;
    }

    .buttons {
      margin-top: $base-margin * 3;
      margin-bottom: $base-padding * 4;
    }
  }

  &__tile {
    margin: 0 $base-padding;
    margin-bottom: 16px;
    box-shadow: #{$shadow-size / 4} #{$shadow-size / 2} $shadow-size $shadow-color;
    border-radius: $shadow-size;

    & > .MuiGrid-container {
      height: 100%;
      padding: #{$base-padding * 2};
    }

    @include media-breakpoint-up("sm") {
      .restaurant-stats__tile {
        &__title {
          padding-bottom: #{$base-padding * 4};
        }

        &__info {
          flex-direction: column;
          padding-bottom: 0;

          & > .MuiGrid-item:first-child {
            padding-bottom: $base-padding * 2;
          }

          .grow {
            flex-grow: 0;
          }
        }
      }
    }

    &__title {
      display: flex;
      padding-bottom: $base-padding;

      .icon {
        padding-right: #{$base-padding + $base-padding / 2};
      }
    }

    &__info {
      padding-bottom: #{$base-padding * 3};

      .label ~ .label {
        padding-left: #{$base-padding + $base-padding / 2};
      }
    }

    &__charts {
      cursor: pointer;
      display: flex;
      height: 100%;

      .restaurant-stats__chart {
        flex-grow: 1;
      }
    }
  }

  &__empty-tile {
    height: $restaurant-stats-shift-tile-height;
    width: 180px;
    margin: 0 $base-padding;
  }

  &__area-tile {
    cursor: pointer;
    height: $restaurant-stats-shift-tile-height;
    width: 180px;
    margin: 0 $base-padding;
    box-shadow: #{$shadow-size / 4} #{$shadow-size / 2} $shadow-size $shadow-color;
    border-radius: $shadow-size;

    & > .MuiGrid-container {
      height: 100%;
      padding: $base-padding #{$base-padding * 2};
    }

    @include media-breakpoint-up("sm") {
      .restaurant-stats__tile {
        &__title {
          padding-bottom: #{$base-padding * 4};
        }

        &__info {
          flex-direction: column;
          padding-bottom: 0;

          & > .MuiGrid-item:first-child {
            padding-bottom: $base-padding * 2;
          }

          .grow {
            flex-grow: 0;
          }
        }
      }
    }

    &__charts {
      display: flex;
      height: 100%;

      .restaurant-stats__chart {
        flex-grow: 1;

        .chart {
          height: 110px;
        }
      }
    }

    &__title {
      display: flex;
      justify-content: center;
    }
  }

  &__chart {
    display: flex;
    flex-direction: column;
    height: 100%;

    .chart {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-grow: 1;
      position: relative;
      height: $restaurant-stats-chart-height;
      margin-bottom: #{$base-padding / 2};
      border-bottom: $border-size solid $restaurant-stats-chart-bar-border-color;

      &__bar {
        width: $restaurant-stats-chart-bar-width;
        margin: 0 $border-size;
        background-color: $restaurant-stats-chart-bar-color;

        &.exceeded {
          background-color: $restaurant-stats-chart-bar-exceeded-color;
        }
      }

      &__line {
        position: absolute;
        left: 0;
        right: 0;
        border-top: $border-size solid $restaurant-stats-chart-line-color;
      }
    }
  }
}
