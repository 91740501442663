.table-plan-creation-details {
  & > .localina-label {
    margin-left: $icon-size + $base-margin;
    padding-bottom: #{$base-padding + $base-padding / 2};
  }

  .localina-buttongroup {
    padding-bottom: $base-padding * 4;
  }

  .localina-checkbox {
    padding-bottom: $base-padding * 3;
  }

  .localina-selectfield {
    .MuiSelect-iconOpen {
      transform: none;
    }
  }
  &-image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    &-container {
      border: 1px #cccccc dashed;
      height: 102px;
      color: #808080;
      padding: 24px;
      position: relative;
    }
  }

  .buttons {
    position: absolute;
    bottom: 0;
    right: 15px;
    .localina-iconbutton {
      height: 36px;
      width: 36px;
      background-color: $secondary-button-background-color;
      border: $border-size solid $border-color;
      border-radius: $border-radius;
      margin: 0 $base-margin/2;
      cursor: pointer;

      .icon {
        display: flex;
      }

      &.Mui-disabled {
        .icon path {
          fill-opacity: 0.2;
        }
      }
    }
  }
}
.restaurant-area-creation-details-belongs-label {
  padding-top: $base-padding * 5;
  margin-bottom: $base-margin;
}
