.table-plan-assign-reservations {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: $shadow-offset $shadow-color;

  .table-plan-assign-reservations-empty {
    display: flex;
    padding: 3 * $base-padding;

    @include media-breakpoint-up("sm") {
      justify-content: center;
    }

    .mini-link {
      padding: 0 #{$base-padding/2};
    }
  }

  .table-plan-reservations-switch-button {
    left: -$base-padding;
    top: $base-padding * 7;
  }

  .table-plan-assign-reservations-canvas-wrapper {
    flex: 1;
    overflow: hidden;
    position: relative;
  }

  .table-plan-assign-reservations-canvas {
    position: relative;
    overflow: hidden;
    height: 100%;
    box-shadow: 0 0 1px 1px black;
  }

  .wall-lines {
    border: 1px solid black;
    background-image: repeating-linear-gradient(-45deg, transparent 0 15px, black 16px);
  }

  .floor-text {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rotate-text {
    p {
      transform: rotate(-90deg);

      /* Legacy vendor prefixes that you probably don't need... */

      /* Safari */
      -webkit-transform: rotate(-90deg);

      /* Firefox */
      -moz-transform: rotate(-90deg);

      /* IE */
      -ms-transform: rotate(-90deg);

      /* Opera */
      -o-transform: rotate(-90deg);
    }
  }
}

.table-plan-assign-reservations-table-container {
  position: absolute;
  text-align: center;
  vertical-align: center;
  background-color: $table-object-blue-color;
  display: flex;
  flex-direction: column;

  &.has-reservations.current-time {
    background-color: $table-object-red !important;
  }

  &.has-reservations-today {
    background-color: $table-object-gray !important;
  }

  &.arrived.current-time {
    background-color: $table-object-green !important;
  }

  .table-plan-assign-reservations-table-info-box {
    padding: 2px 4px;
    color: $table-info-color;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border-radius: 4px;

    &.center {
      display: flex;
      justify-content: center;

      border: none;

      .text-center {
        text-align: center;
      }

      &.arrived.current-time .localina-label.info p {
        color: $table-name-color-green;
      }
    }

    .localina-label.info p {
      font-size: 24px;
      white-space: nowrap;
      font-weight: bold;
    }
  }

  .reservations-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    transition: all 200ms;
    overflow-y: hidden;
    background: $table-info-background;
    border: 1px solid $table-info-color;

    &.has-reservations-today {
      background-color: $table-info-background-gray;
      color: $table-info-color-gray;

      &:hover {
        background-color: $light-blue-color;
        color: $table-info-background;
      }
    }

    &.has-reservations.current-time {
      background: $table-info-background-red;
      color: $table-info-color;

      &:hover {
        background-color: $light-blue-color;
        color: $table-info-color-red;
      }
    }

    &.arrived.current-time {
      background: $table-info-background-green;
      color: $table-info-color;

      &:hover {
        background-color: $light-blue-color;
        color: $table-info-color-green;
      }
    }

    &:hover {
      background-color: $light-blue-color;
      color: $table-object-blue-color;
    }
  }
}

.table-plan-assign-reservations-tooltip {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .row .localina-label.info p {
    font-size: 16px;
  }
}

.table-plan-assign-reservations-popper {
  background: transparent !important;

  & > div {
    background: transparent;
  }

  .table-details {
    border-radius: 16px;
    background-color: white;
    color: $message-subtitle-color;
    box-shadow: 2px 2px 1px #d9d9d9;

    .no-wrap {
      padding-right: $base-padding;

      p {
        white-space: nowrap;
      }
    }

    .header {
      border-radius: 16px 16px 0 0;
      padding: $base-padding #{$base-padding * 2};
      color: black;
      background-color: $reservations-header-color;

      .header-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .icons {
        display: flex;
        align-items: center;

        button {
          margin-left: $base-padding;
          background-color: $speeddial-background-color;
          width: 30px;
          height: 30px;

          .icon svg {
            width: 15px;
            height: 15px;
          }

          .icon path {
            fill: $speeddial-action-color;
          }
        }
      }
    }

    .main {
      padding: $base-padding #{$base-padding * 2};
    }
  }
}

.table-reservation-description-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0;

  &__info {
    min-width: 100px;
    width: 100%;

    .localina-label p {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .action-buttons {
    display: flex;

    .reservation__status {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      flex-shrink: 0;

      .icon {
        height: 100%;
        width: 100%;
      }

      .localina-checkbox {
        padding-bottom: 0;

        & > span {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &.table-preview > div:first-child {
    overflow: hidden;

    p {
      overflow: hidden;
      white-space: break-spaces !important;
      height: 14px;
      text-align: left;

      span {
        display: inline-block;
      }
    }
  }
}
