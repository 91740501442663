.user-role-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;

  .localina-inputfield {
    padding-bottom: 0;
  }
}
