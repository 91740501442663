.table-plan-editor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .flex {
    display: flex;
  }

  .table-plan-editor-canvas-container {
    flex: 1;
    overflow: auto;
    position: relative;

    & > div > div {
      background: white;
      background-image: radial-gradient(red 2px, transparent 0);
      background-size: 100px 100px;
      background-position: -20px -20px;
    }
  }
}
