.localina-buttongroup {
  width: 100%;

  .localina-tooltip__wrapper {
    display: flex;
    flex: 1;
  }

  &__container {
    overflow: auto;
  }

  .MuiButtonGroup-root {
    border-radius: 0;
    height: $buttongroup-height;
    min-height: $buttongroup-height;
    min-width: 100%;
  }

  .localina-buttongroup__tooltip {
    flex: content;
  }

  button.MuiButtonGroup-grouped {
    flex-grow: 1;
  }

  .MuiButton-root {
    border-color: $border-color;
    border-radius: 0;
    font-weight: normal;
    color: $buttongroup-label-color;
    background-color: $buttongroup-background-color;
    white-space: nowrap;
    flex: content;

    &:hover {
      background-color: $border-color;
      border-color: $border-color;
    }

    &.overbooked {
      background-color: $buttongroup-overbooked-background-color;
    }

    &.selected {
      color: $buttongroup-selected-label-color;
      background-color: $buttongroup-selected-background-color;

      &.overbooked {
        background-color: #a1566e;
      }

      path {
        fill: $buttongroup-selected-label-color;
      }
    }
  }

  &:not(.multiple) {
    .MuiButton-root {
      &.selected {
        border-color: $buttongroup-selected-background-color;

        &.overbooked {
          border-color: #a1566e;
        }
      }
    }
  }
}

.buttons-group-button-content-container {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  line-height: 1.5;

  .complex-button-icon-container {
    margin-right: $base-margin;
  }

  .buttons-group-button-content-container-texts {
    display: flex;
    flex-direction: column;

    .buttons-group-button-content-label {
      font-size: $text-big-font-size;
      text-transform: none;
    }

    .buttons-group-button-content-extra-info {
      font-size: $text-small-font-size;
    }
  }
}
