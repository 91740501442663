$drawer-header-height: 100px;
$drawer-footer-height: 72px;

.localina-drawer {
  &__header,
  &__footer {
    background-color: $reservations-header-color;
    padding: 0 #{$base-padding * 3};
    z-index: 1600;
    position: relative;
  }

  &__header {
    height: $drawer-header-height;
    display: flex;
    flex-direction: column;
    box-shadow: $shadow-offset $shadow-color;

    .icon {
      padding-top: #{$base-padding + $base-padding / 2};
      justify-content: flex-end;
    }

    .title {
      flex: 1;
      padding: #{$base-padding * 2} 0;
    }
  }

  &__body {
    height: calc(100% - #{$drawer-header-height});
    margin-top: $shadow-size;

    &__content {
      padding: #{$base-padding * 4} #{$base-padding * 3} #{$base-padding};
      height: calc(100% - #{$drawer-footer-height + $base-padding * 5});
      overflow: auto;

      @include media-breakpoint-down("xs") {
        padding: $base-padding * 4 $base-padding * 2 $base-padding;
      }
    }
  }

  &__footer {
    padding-top: $base-padding * 1.5;
    padding-bottom: $base-padding * 1.5;

    @include media-breakpoint-down("xs") {
      padding: $base-padding * 1.5;
    }

    .buttons {
      .localina-iconbutton {
        background-color: $secondary-button-background-color;
        border: $border-size solid $secondary-button-border-color;
        border-radius: $border-radius;

        .icon {
          display: flex;
        }
      }
    }
  }

  .localina-alert {
    bottom: #{$drawer-footer-height + $base-padding * 3};
  }
}
