.localina-dotsnavigation {
  display: flex;

  .dots {
    display: flex;
    border: $border-size solid $dot-container-color;
    padding: $base-padding #{$dot-indicator-size / 2};
    border-radius: 14px;
    margin: auto;

    &__container {
      flex-grow: 1;
      display: flex;
    }

    .dot {
      width: $dot-indicator-size;
      height: $dot-indicator-size;
      border: $border-size solid $dot-color;
      border-radius: 50%;
      background-color: inherit;
      margin: 0 #{$dot-indicator-size / 2};
      cursor: pointer;

      &.active {
        background-color: $dot-color;
      }
    }
  }
}
