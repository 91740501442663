.localina-checkbox {
  display: flex;
  align-items: center;
  padding-bottom: $base-padding * 3;

  &.has-label {
    .MuiFormControlLabel-root {
      margin: 0;
      width: 100%;

      .MuiCheckbox-root {
        padding: 0;
        margin-right: $base-padding;

        &:hover {
          background-color: initial;
        }
      }

      .MuiFormControlLabel-label {
        @extend .label;
        color: $input-label-color;
      }
    }
  }

  &:not(.has-label) {
    .MuiCheckbox-root {
      padding: 0;
      height: $checkbox-size;
      width: $checkbox-size;
      border: $border-size solid $border-color;
      border-radius: $border-radius;

      &:hover {
        background-color: initial;
      }
    }
  }

  &.has-icon {
    .icon {
      height: $icon-size;
      width: $icon-size;
    }

    &.has-label .MuiFormControlLabel-root,
    &:not(.has-label) .MuiCheckbox-root {
      margin-left: $base-margin;
    }
  }

  &.use-icon {
    &.has-label .MuiFormControlLabel-root,
    &:not(.has-label) .MuiCheckbox-root {
      margin-left: $icon-size + $base-margin;
    }
  }

  .MuiIconButton-label {
    path {
      fill: $icon-color;
    }
  }
}
