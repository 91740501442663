.localina-selectfield {
  .MuiInputBase-root {
    .MuiSelect-select {
      background-color: $input-background-color;
      color: $input-text-color;
      text-align: left;
      -webkit-touch-callout: default;
      -webkit-user-select: text;
      padding-right: #{$icon-size + ($base-padding + $base-padding / 2) * 2};

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 1000px $input-background-color inset !important;
        background-color: $input-background-color !important;
        background-clip: content-box !important;
      }
    }

    .MuiSelect-icon {
      color: $input-label-color;
      padding: #{$base-padding + $base-padding / 2};
      right: 0;
      top: #{($inputfield-height - $icon-size - ($base-padding + $base-padding / 2) * 2) / 2};
    }

    &.Mui-disabled {
      .icon,
      .MuiSelect-icon {
        display: none;
      }
    }
  }
}
