.app-menu {
  .MuiDrawer-paper {
    padding: $base-padding * 3;
    max-width: $app-menu-width;
    right: $base-padding * 4;
    height: calc(100% - #{$base-padding * 6});
  }

  &.permanent {
    width: $app-menu-width;

    .MuiDrawer-paper {
      position: relative;
    }
  }

  .MuiDivider-root {
    background-color: $border-color;
    margin-bottom: $base-padding * 2;
  }

  &__header {
    display: flex;
    padding-bottom: $base-padding * 3;

    .icon path {
      fill: black;
    }
  }

  &__title {
    display: flex;
    padding-bottom: $base-padding * 2;

    .title {
      padding-left: $base-padding * 2;
      color: $app-menu-title-color;
    }
  }

  &__content {
    flex: 1;
    height: 100%;
    overflow: auto;

    .app-menu__category {
      color: $app-menu-category-color;
      padding: #{$base-padding + ($base-padding / 2)} 0;

      &:not(.open) {
        margin-bottom: $base-padding;
      }

      &__item {
        padding: $base-margin #{$base-padding * 4};
        color: $app-menu-category-item-color;
        text-transform: uppercase;
        position: relative;

        &:last-child {
          margin-bottom: $base-padding;
        }

        &.Mui-selected {
          background-color: $app-menu-category-item-selected-color;
        }

        .icon {
          position: absolute;
          left: 6px;
          top: 10px;

          svg {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }

  &__footer {
    padding-top: #{$base-padding / 2};
  }
}

.reservations-left-side-app-menu {
  .MuiDrawer-paper {
    max-width: $app-menu-width;
    right: $base-padding * 4;
    height: 100%;
  }

  &.permanent {
    width: $app-menu-width;

    .MuiDrawer-paper {
      position: relative;
    }
  }
}

.submenu-padding {
  .content {
    padding-left: $base-padding * 3;
  }
}

.app-menu-submenu-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .app-menu-submenu {
    box-shadow: 0 6px 4px -3px $shadow-color;
    z-index: 2;
  }

  .content {
    flex: 1;

    & > div {
      height: 100%;
    }
  }

  .padding-top {
    padding-top: #{$base-padding * 2};
  }
}
