.staff-tags {
  .staff-tag-title {
    display: flex;
    gap: 20px;

    div {
      flex-basis: 50%;
    }
  }

  .staff-tags-empty-label-wrapper {
    padding: $base-padding * 2;
  }

  .localina-label.localina-listitem__left-label.text {
    width: 30px;
  }

  .staff-tags-configuration-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: $base-padding * 2 $base-padding * 2 0;

    & .localina-switch.has-label {
      white-space: nowrap;
      width: auto;
      padding-left: 2 * $base-padding;
    }
  }

  .staff-tags-fab-button {
    background-color: $speeddial-background-color;
  }
}
