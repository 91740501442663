$title-font-size: 26px;

.login,
.register,
.reset-password,
.verify-email {
  &__content {
    padding: #{$base-padding * 4} #{$base-padding * 3};
    height: calc(100% - #{$base-padding * 8});
    max-width: $auth-page-max-width;
    margin: auto;
  }
  &__title {
    font-size: $title-font-size;
    font-weight: bold;
    padding-bottom: $base-padding * 4;
    text-align: center;
  }

  .localina-label {
    &.title {
      padding-bottom: $base-padding * 4;
    }

    &.label {
      margin-bottom: $base-padding * 6;
    }

    &.text {
      padding-bottom: $base-padding * 5;
    }
  }

  .buttons {
    margin-bottom: $base-padding * 8;
    margin-top: $base-padding * 3;
  }
}
