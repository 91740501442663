$sectors-auto-stories-icon-margin-right: 30px;
$sectors-sub-areas-color: rgb(112, 159, 175);
$localina-label-width: 50%;
$card-content-min-height: 164px;

.sectors {
  overflow: auto;

  .restaurant-sectors-main-areas {
    padding: #{$base-padding * 4} #{$base-padding * 3} #{$base-padding};

    .localina-label {
      &.text,
      &.title {
        padding-bottom: $base-padding * 3;
      }
    }

    .auto-stories-icon {
      margin-right: #{$sectors-auto-stories-icon-margin-right};
    }

    .sub-areas-list {
      color: #{$sectors-sub-areas-color};
    }

    .card-actions-align {
      justify-content: flex-end;
    }

    .localina-iconbutton {
      background-color: white;
    }

    .card-style {
      min-height: #{$card-content-min-height};
    }

    .MuiPaper-root {
      height: 100%;

      .MuiCardContent-root {
        display: flex;
        box-sizing: border-box;
        height: 100%;
        padding: $base-padding * 2;

        .card-content__body {
          flex: 1;
        }
      }
    }
  }

  .restaurant-sectors-areas {
    height: calc(100% - #{$base-padding * 48});

    & .localina-listitem__left-icon {
      display: flex;
      align-items: center;
    }

    .localina-label {
      display: inline;
      width: #{$localina-label-width};

      &.localina-listitem__left-label {
        width: auto;
        margin-right: $base-margin * 2;

        &:active {
          visibility: hidden;
        }
      }

      &.title {
        display: inline-flex;
        padding-bottom: $base-padding * 3;
        margin-left: $base-padding * 4;
        margin-top: $base-padding * 4;
      }
    }
  }

  .sectors-fab-button {
    margin-right: #{$speeddial-right};
    position: fixed;
    bottom: #{$speeddial-bottom};
    right: #{$speeddial-right};
    background-color: #{$speeddial-background-color};
    width: #{$speeddial-height};
    height: #{$speeddial-width};
  }
}
