.guest {
  overflow: visible;

  .guest-reservations-company {
    margin-top: $base-padding * 3;
    margin-left: $base-padding * 5;
  }

  .guest-reservations-email {
    display: flex;
    margin-top: $base-padding * 2;
    margin-left: $base-padding * 5;
  }

  .edit-icon {
    margin-right: $base-padding * 5;
  }

  .reservations-grid {
    margin-top: $base-padding * 2;
    margin-left: $base-padding * 5;
  }

  .guest-phone-number-style {
    margin-top: $base-padding * 2;
    margin-left: $base-padding * 5;
  }

  .favorite-style {
    width: 20px;
    height: 22px;
  }

  .full-width {
    width: 100%;
  }

  .jusitify-content-icon {
    justify-content: left;
  }

  .grid-width {
    max-width: 6%;
  }

  .icons-style {
    margin-top: $base-padding * 2;
    margin-bottom: $base-padding * 2;
    margin-left: $base-padding * 5;
  }

  #guest {
    height: calc(100% - #{$base-padding * 6});
    padding: #{$base-padding * 3} 0;
    overflow: auto;

    .guestDetailsContainer {
      display: flex;
      padding: $base-padding * 3;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      overflow: auto;

      & .tablesWrapper {
        flex: 1;
        display: flex;
        flex-wrap: wrap;

        & > div {
          box-shadow: none;
          width: auto;
        }

        & table {
          width: auto;

          & td {
            border-bottom: unset;
            vertical-align: baseline;

            & .favoritePhoneNumber {
              display: flex;
              flex-wrap: nowrap;
              gap: 10px;
            }

            &:first-child:not(:last-child) {
              min-width: 110px;

              & p::after {
                content: ":";
              }
            }
          }
        }
      }
    }
  }

  .guest-reservations.text {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    top: 0;
    right: #{$base-padding * 3 - ($fabbutton-size - $icon-size)/2};
    z-index: 1;

    p {
      width: $fabbutton-size;
      height: $fabbutton-size;
      color: $guest-reservations-counter-color;
      background: $guest-reservations-counter-background-color;
      border: #{$border-size * 5} solid $guest-reservations-counter-border-color;
      box-sizing: border-box;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  .localina-accordion {
    &.MuiAccordion-root .MuiAccordionDetails-root {
      padding: 0px 0px;
    }

    &__summary {
      .title {
        color: $guest-details-title-color;
      }

      svg path {
        fill: $guest-details-title-color;
      }
    }

    &__details {
      .MuiGrid-container .MuiGrid-container {
        align-items: center;

        &:first-child {
          padding-bottom: #{$base-padding * 3};
        }

        .label:first-child {
          padding-bottom: #{$base-padding / 2};
        }
      }
    }
  }

  .guest__subheader {
    .title {
      color: $guest-details-title-color;
      padding: $base-padding #{$base-padding * 3};
    }
  }
}
