.slider {
  margin: 0 $base-margin;
  width: 100%;
  height: 2px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 2px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #003a5c !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.slider::-moz-range-thumb {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #003a5c;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.zoom-slider {
  display: flex;
  flex-direction: row;
  align-items: center;

  .minus-plus-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 24px;
    height: 24px;
    justify-content: center;

    span {
      position: absolute;
      top: 2px;
    }
  }
}

.table-plan-shift-slider {
  display: flex;
  gap: $base-margin;
  flex-direction: row;
  align-items: center;
  margin-right: $base-margin * 2;
  width: 200px;

  .localina-slider {
    display: flex;
    width: 100%;
    padding-bottom: 0;

    .MuiSlider-root {
      margin-top: 0;
    }
  }

  .shift-time {
    width: 47px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 12px;
  }
}

.zoom-slider {
  display: flex;
  gap: $base-margin;
  flex-direction: row;
  align-items: center;
  width: 200px;

  .localina-slider {
    display: flex;
    width: 100%;
    padding-bottom: 0;

    .MuiSlider-root {
      margin-top: 0;
    }
  }

  .minus-plus-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 24px;
    height: 24px;
    justify-content: center;

    span {
      position: absolute;
      top: 2px;
    }
  }
}
