.localina-slider {
  padding-bottom: $base-padding * 3;

  .MuiSlider-root {
    margin-top: $base-padding * 5;
    height: $slider-rail-height;
    border-radius: 1px;

    .MuiSlider-rail {
      background-color: $slider-line-color;
    }

    .MuiSlider-track {
      background-color: $slider-line-active-color;
      border: none;
    }

    .MuiSlider-mark {
      display: none;
    }

    .MuiSlider-markLabel {
      color: $slider-label-color;
    }

    .MuiSlider-thumb {
      color: $slider-cursor-color;
    }

    .MuiSlider-valueLabel {
      top: -16px;
      width: $slider-bubble-size;
      height: $slider-bubble-size;
      padding: 0;
      border-radius: 50%;
      background-color: $slider-bubble-color;

      &::before {
        content: none;
      }

      &::after {
        content: "";
        background-color: $slider-cursor-color;
        width: 2px;
        height: 16px;
        position: absolute;
        top: 100%;
        left: 48%;
      }

      .MuiSlider-valueLabelLabel {
        color: $slider-bubble-label-color;
      }
    }
  }

  .localina-label {
    color: $slider-label-color;
    padding: #{$base-padding + $base-padding / 2} #{$base-padding + $base-padding / 2} 0;
  }
}
