.date-picker-dialog {
  .date-range-toolbar {
    display: flex;
    flex-direction: column;
    padding: $base-padding;

    div[role="group"]:not(.date-range-toolbar__dates-button-group) {
      padding-bottom: $base-padding;

      & > button {
        flex: 1;
        border: none;
      }
    }

    &__dates-button-group {
      justify-content: center;

      & > button {
        justify-content: flex-start;
        border: none;
        background-color: transparent !important;
      }
    }
  }

  .MuiPickersLayout-toolbar {
    max-width: none;

    h4 {
      margin: 0;
    }
  }

  .date-picker-action-bar {
    display: flex;
    justify-content: space-between !important;
  }

  .MuiPickersLayout-root {
    flex-direction: column;
  }

  .MuiPickersLayout-root {
    display: flex;
    flex-direction: column;
  }

  &.hidden-calendar {
    .MuiPickersLayout-root {
      .date-range-toolbar {
        background-color: #f0f5f7;
        width: 300px;

        padding: $base-padding * 2 $base-padding * 2 $base-padding $base-padding * 2;
        justify-content: space-between;

        .toggle-buttons-info-message {
          margin-bottom: $base-margin * 2;
        }

        button {
          padding: $base-padding * 2;
          border-radius: $border-radius * 2;
          margin-bottom: $base-margin;
        }
      }

      .MuiPickersLayout-contentWrapper {
        display: none;
      }

      .MuiCalendarPicker-root,
      .date-picker-action-bar {
        display: none !important;
      }
    }

    div[role="group"]:not(.date-range-toolbar__dates-button-group) {
      padding-bottom: 0;

      & > button {
        border: 1px solid #003a5c !important;
        color: #003a5c;
      }
    }
  }
}

.helper-text-tooltip {
  max-width: 240px !important;
  text-align: justify;
  text-align-last: justify;

  & > div {
    max-height: 300px !important;
    overflow-y: scroll;
  }
}
