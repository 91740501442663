.localina-tooltip__wrapper {
  .icon svg {
    width: 20px;
    height: 20px;
  }
}

.multiline-text {
  white-space: pre-wrap;
}
