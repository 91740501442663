$clock-icon-z-index: 999;
$clock-icon-opacity: 0.3;
$clock-icon-margin-right: 10px;

.localina-datefield,
.localina-timefield {
  .MuiInputBase-root {
    height: $inputfield-height;

    &.Mui-disabled {
      .MuiInputAdornment-root {
        display: none;
      }
    }
  }

  .MuiInputAdornment-root {
    margin: 0;

    .MuiIconButton-root {
      &:hover {
        background-color: initial;
      }
    }
  }

  .MuiFormHelperText-root.Mui-error {
    display: none;
  }

  &__datepicker,
  &__timepicker {
    position: fixed !important;
    inset: 0 !important;
    transform: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);

    .PrivatePickersToolbar-root {
      .MuiIconButton-root {
        display: none;
      }
    }

    .MuiPickersDay-root {
      &.Mui-selected {
        background-color: $datepicker-color;
      }

      &.MuiPickersDay-today:not(.Mui-selected) {
        border-color: $datepicker-color;

        &:not(.Mui-disabled) {
          color: $datepicker-color;
        }
      }

      &.highlight,
      &.highlight.MuiPickersDay-today {
        color: $error-color;
      }
    }
  }

  .disabled-clock-icon {
    z-index: #{$clock-icon-z-index};
    opacity: #{$clock-icon-opacity};
    position: absolute;
    right: 0;
    margin-right: #{$clock-icon-margin-right};
  }

  .field-container {
    position: relative;
  }
}

.localina-timefield__timepicker {
  .MuiDialog-paper.MuiPaper-root {
    min-width: 250px;
  }

  .MuiMultiSectionDigitalClockSection-root {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    & li {
      width: 100%;
    }
  }

  .MuiPickersLayout-root {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-width: 180px;
  }

  .MuiPickersToolbar-root {
    align-items: center;
    max-width: unset;

    .MuiPickersToolbar-content {
      width: auto;
    }
  }

  .MuiMultiSectionDigitalClock-root {
    justify-content: center;
  }
}

.localina-timefield__timepicker__popper {
  &.MuiPopper-root {
    z-index: 100;
  }

  .MuiPickersToolbar-root {
    align-items: center;
    max-width: unset;

    .MuiPickersToolbar-content {
      width: auto;
    }
  }

  .MuiPickersLayout-root {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-width: 175px;
  }

  .MuiMultiSectionDigitalClockSection-root {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    & li {
      width: 100%;
    }
  }
}

.date-picker-dialog {
  .date-picker-toolbar {
    padding: $base-padding * 2 $base-padding * 2 0;
  }

  .date-picker-action-bar {
    display: flex;
    justify-content: flex-end;
    padding: $base-padding;
  }
}
