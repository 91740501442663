.localina-listitem.MuiListItemButton-root {
  padding: $base-padding #{$base-padding * 3};
  border-bottom: $border-size solid $border-color;
  display: flex;

  &:first-child {
    border-top: $border-size solid $border-color;
  }

  &.Mui-disabled {
    opacity: 1;
  }

  .text {
    color: $listitem-title-color;

    .MuiTypography-root {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .info {
    color: $listitem-subtitle-color;
    padding-top: $base-padding / 2;
  }

  .localina-listitem__left-icon {
    padding-right: $base-padding * 2;
  }

  .localina-listitem__right-icon {
    padding-left: $base-padding * 2;
  }

  .localina-listitem__content {
    width: 100%;
  }

  &.has-left-icon,
  &.has-right-icon {
    .localina-listitem__content {
      width: calc(100% - #{$icon-size + $base-padding * 2});
    }
  }

  &.has-left-icon.has-right-icon {
    .localina-listitem__content {
      width: calc(100% - #{$icon-size * 2 + $base-padding * 4});
    }
  }
}
