$grid-breakpoints: (
  xs: 480px,
  sm: 600px,
  md: 960px,
  lg: 1280px,
  xl: 1920px,
);

@mixin media-breakpoint-down($size, $breakpoints: $grid-breakpoints) {
  $max-width: map_get($breakpoints, $size);
  @if $max-width {
    @media (max-width: $max-width - 1) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-up($size, $breakpoints: $grid-breakpoints) {
  $min-width: map_get($breakpoints, $size);
  @if $min-width {
    @media (min-width: $min-width) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min-width: map_get($breakpoints, $lower);
  $max-width: map_get($breakpoints, $upper);

  @if $min-width != null and $max-width != null {
    @media (min-width: $min-width) and (max-width: $max-width - 1) {
      @content;
    }
  } @else {
    @content;
  }
}
