.canvas-controller {
  height: 40px;
  background-color: $light-blue-color;
  display: flex;
  align-items: center;
  padding: 0 $base-padding * 2;

  .icon {
    margin-left: $base-margin * 2;
    cursor: pointer;
  }

  .icon svg {
    height: 18px;
    width: 18px;
  }

  .icon:first-child {
    margin-left: 0;
  }

  .save-icon {
    width: 30px;
    height: 30px;
    border-radius: 10px;
  }

  .save-icon-canvas-modified {
    background-color: rgba(224, 193, 248, 0.82);
  }
}

.table-plane-name {
  margin-left: $base-margin * 2;
  flex: 1;
}
