.localina-spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiCircularProgress-root {
    color: $icon-color;
  }

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: $zindex-spinner;

    .MuiCircularProgress-root {
      color: $loading-icon-color;
    }
  }
}
