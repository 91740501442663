.localina-inputfield {
  padding-bottom: $base-padding * 3;
  width: 100%;

  &.has-icon {
    .icon {
      height: $icon-size;
      width: $icon-size;
    }

    .MuiFormControl-root {
      margin-left: $base-margin;
    }
  }

  &.use-icon {
    .MuiFormControl-root {
      margin-left: $icon-size + $base-margin;
    }
  }

  &.has-icon,
  &.use-icon {
    .helper-text {
      margin-left: $icon-size + $base-margin;
    }
  }

  .field-container {
    display: flex;
    align-items: center;
  }

  .MuiInputLabel-root {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.MuiInputLabel-shrink.Mui-disabled,
    &.MuiInputLabel-shrink:not(.Mui-error) {
      color: $input-label-color;

      .MuiFormLabel-asterisk {
        color: $input-label-color;
      }
    }

    &.Mui-error {
      color: $error-color;

      .MuiFormLabel-asterisk {
        color: $error-color;
      }
    }
  }

  .MuiInputBase-root {
    background-color: $input-background-color;
    min-height: $inputfield-height;

    &.MuiInputBase-multiline {
      padding: $base-padding 0;
      .MuiInputBase-input {
        padding: 0 #{$base-padding + $base-padding / 2};
      }
    }

    .MuiInputBase-input {
      color: $input-text-color;
      padding: #{$base-padding + $base-margin} #{$base-padding + $base-padding / 2};
      opacity: 1;
      -webkit-text-fill-color: $input-text-color;
      -webkit-touch-callout: default;
      -webkit-user-select: text;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 1000px $input-background-color inset !important;
        background-color: $input-background-color !important;
        background-clip: content-box !important;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &.MuiInputBase-inputAdornedEnd {
        padding-right: 0;
      }
    }

    &:not(.Mui-error) {
      .MuiOutlinedInput-notchedOutline {
        border-color: $border-color;
      }

      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: $border-focused-color;
        }
      }
    }

    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: $error-color;
      }
    }
  }

  .helper-text {
    color: $input-label-color;
    padding: $base-padding #{$base-padding + $base-padding / 2} 0;
  }
}
