.localina-fabbutton.MuiFab-root {
  bottom: $fabbutton-bottom;
  right: $fabbutton-right;
  margin-right: $fabbutton-right;

  height: $fabbutton-size;
  width: $fabbutton-size;
  background-color: $fabbutton-background-color;
  box-shadow: $shadow-offset $shadow-color;

  &.Mui-disabled {
    background-color: $fabbutton-disabled-background-color;
  }

  .icon path {
    fill: $fabbutton-color;
  }

  .MuiFab-label {
    color: $fabbutton-color;
    display: flex;
    align-items: center;

    .icon {
      &,
      svg {
        height: $fabbutton-icon-size;
        width: $fabbutton-icon-size;
      }

      path {
        fill: $fabbutton-color;
      }
    }
  }

  &.position-fixed {
    position: fixed;
  }
}
