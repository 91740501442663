.sales-statistics {
  display: flex;
  flex-direction: column;
  height: 100%;

  $textFieldMaxWidth: 500px;
  $dateFieldsMaxWidth: 400px;

  .filters-wrapper {
    display: flex;
    padding: $base-padding * 3 $base-padding * 2;
    gap: $base-margin;
    justify-content: flex-start;
    align-items: center;

    .spacer {
      flex-grow: 1;
      max-width: calc(50% - $textFieldMaxWidth - $dateFieldsMaxWidth / 2);
    }

    @include media-breakpoint-down("md") {
      flex-direction: column;
      align-items: center;
    }

    .localina-textfield {
      max-width: $textFieldMaxWidth;
      padding-bottom: 0;
    }

    .datefields-wrapper {
      display: flex;
      flex-direction: column;
      max-width: $dateFieldsMaxWidth;

      .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary {
        background-color: #f0f5f7;
      }

      &.error {
        .localina-datefield fieldset {
          border-color: #821e3e;
        }
      }

      .datefields-container {
        display: flex;
        gap: $base-margin;

        .localina-datefield {
          max-width: 200px;
          padding-bottom: 0;
        }
      }

      .date-fields-error {
        padding: $base-padding $base-padding * 1.5 0;
      }
    }
  }

  .table-container {
    height: 100%;
  }

  thead {
    background-color: white;

    & th {
      color: #005e6e;
      padding-bottom: $base-padding;

      &.MuiTableCell-head.MuiTableCell-alignCenter > .localina-label {
        justify-content: center;
      }

      .localina-label {
        text-align: left;

        &.text.center p {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;

          span {
            text-align: center;
          }
        }
      }
    }
  }

  tbody {
    th .localina-label.info {
      color: #005e6e;
    }
  }

  .border-bottom {
    border-bottom: 1px solid #afafaf;
  }

  .border-right {
    border-right: 1px solid #afafaf;
  }

  .border-left {
    border-left: 1px solid #afafaf;
  }

  .border-bottom-and-right {
    border-bottom: 1px solid #afafaf;
    border-right: 1px solid #afafaf;
  }

  .border-bottom-and-left {
    border-bottom: 1px solid #afafaf;
    border-left: 1px solid #afafaf;
  }

  .main-row {
    color: #005e6e;
    background: #f0f5f7;
  }
}
