$box-background-color: #d4e2e7;
$box-border-radius: 16px;
$box-height: 48px;
$content-margin-top: 11px;

.closingday-view {
  .row {
    display: flex;
    margin: 0 -#{$base-margin};
    width: calc(100% + #{$base-margin * 2});

    .localina-timefield,
    .localina-datefield {
      margin: 0 #{$base-margin};
    }
  }

  .localina-label {
    &.info,
    &.text {
      padding-bottom: $base-padding * 2;
    }

    &.info {
      color: $listitem-subtitle-color;
    }
  }

  .title-date-time {
    margin-bottom: $base-padding * 2;
  }

  .title-areas {
    padding-top: $base-padding * 3;
    margin-bottom: $base-padding * 2;
  }

  .area-box-item {
    background-color: #{$box-background-color};
    width: 100%;
    height: #{$box-height};
    border-radius: #{$box-border-radius};
    margin-bottom: $base-padding * 2;
  }

  .icon-style {
    float: left;
    width: 15%;
    display: inline;
    margin-top: #{$content-margin-top};
    margin-left: #{$content-margin-top};
  }

  .typography-style {
    float: left;
    width: 65%;
    display: inline;
    margin-top: #{$content-margin-top};
  }

  .localina-switch {
    padding-top: $base-padding / 2;
  }
}
