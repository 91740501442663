$reservation-info-padding-bottom: 20px;
$localina-label-min-width: 200px;

.reservation-list-shiftgroup {
  &__header {
    padding-left: $base-padding #{$base-padding * 2};
    padding-right: $base-padding #{$base-padding * 2};
    background-color: $reservations-header-color;
    border-radius: 8px 8px 0px 0px;
    display: flex;

    &__info {
      display: flex;

      .localina-label.info:first-child {
        padding-right: $base-padding;
      }
    }
  }

  .reservation-dragging {
    background-color: $light-blue-color;
  }

  .unassigned-reservation {
    background-color: $reservation-list-unassigned-shift-background-color;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      background-color: $error-color;
      right: 0;
      width: 7px;
      bottom: 0;
      top: 0;
      left: 0;
    }
  }

  &__reservation-item {
    border-bottom: $border-size solid $border-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $base-padding;
    position: relative;

    background-color: rgba(255, 255, 255, 0.6);
    min-width: 293px;

    .reservation__status {
      .localina-checkbox {
        padding: 0;
      }

      width: 54px;

      & > div {
        height: 46px;
      }
    }

    .align-space-between {
      display: flex;
      padding-right: #{$base-padding * 2};
      flex: 1;
      flex-direction: column;
      justify-content: space-between;

      .info-row {
        display: flex;
        justify-content: space-between;
        padding-bottom: $base-padding;
        gap: $base-margin;

        .clock-group {
          flex-basis: 40%;

          @include media-breakpoint-down("sm") {
            flex-basis: 60%;
          }
        }

        .icon-group {
          display: flex;
          flex-basis: 30%;

          .flex {
            display: flex;
          }

          .elipsis {
            .MuiTypography-root {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              width: 30px;
            }
          }
        }
      }

      .guest-info-container {
        display: flex;
        align-items: center;
        flex-basis: 70%;

        .additional-info {
          flex-basis: 10%;
          padding-right: #{$base-padding / 2};
        }

        .localina-label {
          width: 100%;
        }
      }

      .guest-info {
        color: $guest-details-title-color;

        &.bold {
          font-weight: bold;
        }

        .MuiTypography-root {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 105px;
          font-size: $text-big-font-size;
        }

        &.padding-left {
          padding-left: 20px;
        }
      }

      .area-code {
        color: $guest-details-title-color;

        .MuiTypography-root {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 55px;
        }
      }

      .reservation-guest-additional-info-icon-container {
        display: flex;
        align-items: center;

        svg {
          width: 20px;
          height: 20px;
        }

        &:not(.no-additional-info) path {
          fill: $error-color;
        }
      }

      .info-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .guest-status-vip,
      .guest-status-blacklist {
        display: flex;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    &__cancelled {
      opacity: 0.3;
    }

    &__requested {
      background-color: $reservations-requested-background-color;
    }

    &:last-child {
      .reservations-group__time-group__reservation {
        border-bottom: $border-size solid $border-color;
      }
    }

    &__color-indicator {
      position: absolute;
      width: $base-padding;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }
}

.tooltip {
  background: transparent !important;
}

.reservation-table-numbers > div,
.basic-blue-tooltip-container > div {
  background-color: $buttongroup-selected-background-color;
}

.additional-info-poper > div {
  background: transparent !important;
}

.additional-info-tooltip {
  border-radius: 16px;
  background-color: white;
  color: $message-subtitle-color;
  box-shadow: 2px 2px 1px #d9d9d9;

  .header {
    padding: $base-padding #{$base-padding * 2};
    color: black;
    background-color: $reservations-header-color;
  }

  .header:first-child {
    border-radius: 16px 16px 0 0;
  }

  .main {
    padding: $base-padding #{$base-padding * 2};
  }
}
