.notifications-view {
  .localina-drawer__body__content {
    padding: $base-padding * 2 $base-padding * 3 $base-padding;
    height: calc(100% - ($drawer-footer-height + $base-padding * 3));
  }

  .localina-tabs {
    box-shadow: 0 6px 4px -3px $shadow-color;
  }

  &.no-data {
    .localina-tabs {
      margin-bottom: $base-padding * 2;
    }
  }

  .localina-listitem {
    padding-left: 0;
    padding-right: 0;
  }

  .requested-reservation-change-status-options {
    display: flex;
    gap: 30px;
    padding: 0 10px;

    .loading-spinner {
      position: absolute;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.4);
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
    }

    .localina-iconbutton {
      border-radius: 5px;
      //border: 1px solid;

      &.accept-button {
        border-color: green;

        color: green;
        background-color: rgba(144, 238, 144, 0.3);
      }

      &.decline-button {
        background-color: rgba(205, 92, 92, 0.3);

        border-color: darkred;

        path {
          fill: darkred;
        }
      }

      & svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  .is-processed {
    opacity: 0.5;

    .localina-iconbutton {
      opacity: 0.8;
    }
  }

  .notification-updated-item {
    .localina-label.info,
    .localina-listitem__left-label {
      color: rebeccapurple;
    }
  }

  .guest-cancelled {
    .localina-label {
      color: $error-color;
    }

    svg {
      path {
        fill: $error-color;
      }
    }
  }
}
