.reservation-view {
  .reservation-details {
    & > .localina-label {
      padding-bottom: #{$base-padding + $base-padding / 2};

      &.total-visits-count {
        padding-bottom: $base-padding * 3;
      }
    }

    .localina-buttongroup {
      padding-bottom: $base-padding * 3;
    }

    .localina-checkbox {
      padding-bottom: $base-padding * 3;
    }

    .localina-selectfield {
      .MuiSelect-iconOpen {
        transform: none;
      }
    }

    .form-field {
      padding-bottom: 0;

      .reservation-view-switch {
        padding-bottom: $base-padding * 3;

        .localina-switch.has-label {
          padding-bottom: 0;
        }

        .helper-text {
          padding-left: 0;
        }
      }

      .localina-switch.has-label {
        justify-content: space-between;
      }
    }

    .date-time-row {
      .date-time-row-icon-container {
        width: $icon-size;
        margin-right: $base-margin;
        margin-bottom: $base-padding * 3;
        display: flex;
        align-items: center;
      }

      .date-time-spacer {
        width: $icon-size + $base-margin;
        @include media-breakpoint-down("xs") {
          width: $icon-size;
        }
      }
    }

    & > .title {
      padding-bottom: $base-padding * 3;
    }

    .guest-title-divider {
      margin-bottom: 18px;
    }

    .audit-container {
      .audit-row {
        display: flex;
        padding-bottom: #{3 * $base-padding};
        padding-left: #{5 * $base-padding};

        .audit-header {
          flex-basis: 30%;
        }
      }
    }

    .localina-inputfield.has-icon svg {
      fill: $icon-default-color;
    }

    & > .localina-label.text {
      padding-bottom: 3 * $base-padding;
    }
  }

  .existing-guest-info {
    display: flex;
    justify-content: space-between;
    padding-bottom: $base-padding * 3;

    &.hidden {
      visibility: hidden;
    }
  }

  .selection-focus-rectangle {
    background-color: $step-inactive-background-color;
    border-radius: $base-padding * 2;
    padding: $base-padding;
    margin-bottom: $base-padding * 3;

    .localina-buttongroup:last-child {
      padding-bottom: 0;
    }

    .MuiCircularProgress-root {
      width: $icon-size-small !important;
      height: $icon-size-small !important;
    }

    &.error {
      border: 1px solid $error-color;
    }

    .form-field > div:first-child {
      margin-bottom: $base-margin;
    }
  }

  .shift-title-details-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: $base-padding;

    .shift-title-spacer {
      margin-right: $base-padding/2;
    }

    .info {
      position: relative;
      top: 1px;
    }
  }

  .reservation-fileupload {
    display: flex;
    flex-direction: column;
    margin-bottom: $base-padding * 3;

    &__input-wrapper {
      display: flex;
      height: 48px;
      width: 100%;
      justify-content: space-between;

      label {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0;

        span[role="button"] {
          border: none;
          width: auto;
          padding: 0 12px;
          display: flex;
          justify-content: flex-end;
        }

        .preview-file-name {
          display: flex;
          gap: $base-padding;

          .preview-image {
            max-width: 200px;
            max-height: 75px;
            display: flex;
            justify-content: flex-end;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }

          .file-name {
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .icon-size svg {
            height: 32px;
            width: 32px;
          }
        }

        span.MuiFormControlLabel-label {
          white-space: nowrap;
        }
      }
    }

    .MuiDialog-root {
      z-index: 1700;

      .MuiDialog-paper {
        max-width: unset;
        height: 100%;
        width: 100%;
        background: transparent;

        .preview-image-dialog-container {
          width: 100%;
          height: 100%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
              0 1px 5px 0 rgba(0, 0, 0, 0.12);
          }
        }
      }
    }
  }

  .localina-drawer__footer {
    .MuiAlert-root {
      margin-bottom: $base-padding;
    }
  }

  .localina-drawer__body__content {
    .reservation-details {
      position: relative;

      .disabled-form-mask {
        z-index: 5000;
        position: absolute;
        top: -$base-padding * 4;
        bottom: -$base-padding * 2;
        left: -$base-padding * 3;
        right: -$base-padding * 3;
        cursor: default;
        background-color: black;
        opacity: 0.3;
      }
    }

    .recreate-reservation-link {
      padding: $base-padding * 2 0;
    }
  }

  .localina-drawer__body {
    display: flex;
    flex-direction: column;

    .staff-tag-button-group-wrapper {
      padding-bottom: $base-padding;
    }
  }

  .requested-reservation-change-status-options {
    display: flex;
    gap: $base-padding * 2;
  }
}

.date-picker-dialog .restaurant-closed-day {
  color: lightgray;
}

.reservation-details-color-option-label {
  display: flex;
  gap: $base-margin;

  .color-placeholder {
    width: $base-margin * 3;
  }
}

.requested-reservation-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: $base-padding * 4;

  .requested-reservation-details-wrapper {
    display: flex;
    flex-direction: column;
    gap: $base-padding;

    &.phone-and-email {
      .detail-value {
        text-decoration: underline;
      }
    }

    .requested-reservation-detail {
      display: flex;
      gap: $base-padding;

      .detail-description {
        p {
          display: none;
          @include media-breakpoint-up("xs") {
            display: block;
          }
        }
      }

      .detail-value {
        overflow: hidden;

        p {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-weight: 500;
          color: $petrol-dark;
        }
      }

      &.guest-name-information .detail-value p {
        display: inline-flex;
        align-items: center;
        gap: $base-padding;

        span {
          display: flex;
        }
      }
    }
  }
}

.review-result-information {
  margin-top: $base-padding * 4;
}
