$base-padding: 8px;
$base-margin: 10px;

$header-height: 64px !default;
$header-stepper-height: 112px !default;
$footer-height: 52px !default;
$tab-indicator-size: 3px !default;
$icon-size-small: 20px !default;
$icon-size: 24px !default;
$icon-size-medium: 32px !default;
$icon-size-big: 40px !default;
$inputfield-height: 56px !default;
$button-height: 48px !default;
$buttongroup-height: 56px !default;
$fabbutton-icon-size: 32px !default;
$iconbutton-size: 48px !default;
$checkbox-size: 54px !default;
$drawer-width: 480px !default;
$drawer-max-width: 85% !default;
$dot-indicator-size: 12px !default;
$slider-rail-height: 2px !default;
$slider-bubble-size: 32px !default;

$orange: #eabe24;
$petrol-light: #709faf;
$petrol-dark: #003a5c;

$background-light-blue: #f0f5f7;

$border-size: 1px !default;
$border-color: #cfd4d9 !default;
$border-focused-color: black !default;
$border-radius: 4px !default;

$shadow-size: 4px !default;
$shadow-offset: 0px $shadow-size $shadow-size !default;
$shadow-color: rgba(0, 0, 0, 0.25) !default;

$header-title-color: white !default;
$header-background-color: #003a5c !default;
$footer-background-color: #cfcfcf !default;

$step-inactive-background-color: #f0f5f7 !default;
$step-inactive-label-color: rgba(0, 0, 0, 0.37) !default;
$step-completed-background-color: #709faf !default;
$step-completed-label-color: white !default;

$tab-indicator-background-color: #709faf !default;
$tab-label-color: #000000 !default;
$tab-selected-label-color: #005e6e !default;

$icon-color: #003a5c !default;
$loading-icon-color: white !default;
$datepicker-color: #4078cc !default;
$notification-color: #f29698 !default;
$dot-color: #003a5c !default;
$dot-container-color: #bfd4db !default;

$error-color: #821e3e !default;
$link-color: #338fb8 !default;

$input-label-color: black !default;
$input-text-color: #4a4a4a !default;
$input-background-color: white !default;

$listitem-title-color: black !default;
$listitem-subtitle-color: #709faf !default;

$primary-button-label-color: white !default;
$primary-button-background-color: #821e3e !default;
$primary-button-disabled-color: #c08e9e !default;
$secondary-button-disabled-color: #dfc6ce !default;
$secondary-button-border-color: #404040 !default;
$secondary-button-label-color: #404040 !default;
$secondary-button-background-color: white !default;

$buttongroup-label-color: #404040 !default;
$buttongroup-background-color: white !default;
$buttongroup-selected-label-color: white !default;
$buttongroup-selected-background-color: #709faf !default;
$buttongroup-overbooked-background-color: #fdf2f3 !default;

$guest-status-vip-color: goldenrod !default;
$guest-status-blacklist-color: #821e3e !default;

$iconbutton-background-color: #f0f5f7 !default;
$iconbutton-color: #003a5c !default;
$iconbutton-disabled-background-color: lightgrey !default;
$iconbutton-disabled-color: #404040 !default;

$fabbutton-background-color: #821e3e !default;
$fabbutton-color: white !default;
$fabbutton-disabled-background-color: #404040 !default;

$reservation-list-unassigned-shift-background-color: #f5e7e7;

$speeddial-background-color: #005e6e !default;
$speeddial-height: 56px;
$speeddial-width: 56px;
$speeddial-bottom: 24px;
$speeddial-right: 24px;
$speeddial-background-color: #005e6e !default;
$speeddial-action-background-color: #709faf !default;
$speeddial-action-color: white !default;
$speeddial-action-disabled-background-color: #bfd4db !default;
$speeddial-margin-right: 40px;
$speeddial-min-width: 130px;
$speeddial-inset: 14px;

$fabbutton-size: $speeddial-width;
$fabbutton-right: $speeddial-right;
$fabbutton-bottom: $speeddial-bottom;

$switch-background-color: #bfd4db !default;
$switch-color: white !default;
$switch-checked-background-color: #90d2e8 !default;
$switch-checked-color: #709faf !default;

$alert-error-background-color: #b4788b;
$alert-error-color: white;
$alert-success-background-color: #005e6e;
$alert-success-color: white;

$searchfield-height: 40px;
$searchfield-border-color: #821e3e;
$sortfield-height: 40px;

$slider-line-color: #bfd4db;
$slider-line-active-color: #338fb8;
$slider-cursor-color: #003a5c;
$slider-bubble-color: #b2d5e4;
$slider-bubble-label-color: #ffffff;
$slider-label-color: #000000;

$accordion-summary-height: 40px;

$zindex-header: 1000;
$zindex-backdrop: 1010;
$zindex-drawer: 1300;
$zindex-spinner: 1310;

$text-big-font-size: 16px;
$text-medium-font-size: 14px;
$text-small-font-size: 12px;
