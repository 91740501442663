.import-metadata-speeddial {
  .MuiSpeedDialAction-staticTooltipLabel {
    .localina-label.info .MuiTypography-root {
      white-space: nowrap;
    }
  }

  button.MuiButtonBase-root {
    .localina-label.info .MuiTypography-root {
      color: white;
    }
  }

  .MuiSpeedDialIcon-root {
    height: auto;
    display: flex;
  }
}

.import-group-metadata-dialog {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: $base-padding * 3 0;
  overflow: hidden;

  .import-metadata-progress-container {
    width: 100%;
    max-width: 400px;
    padding: $base-padding * 2;

    &:not(:first-child) {
      border-top: 1px solid gray;
    }
  }

  .import-file-name {
    svg {
      fill: $icon-color;
      font-size: $icon-size-small;
    }

    &.localina-label p.MuiTypography-root {
      margin-left: $base-margin/2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .imports-container {
    display: flex;
    flex-direction: column;
    overflow: auto;

    .localina-accordion.MuiAccordion-root .MuiAccordionDetails-root {
      padding: 0 $base-padding * 3;
    }

    .MuiAccordionSummary-content .localina-label.title h6 {
      font-size: 15px;
    }

    .localina-accordion__details {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      align-items: center;
    }
  }

  .dialog-title {
    margin-bottom: $base-margin;
    font-size: $text-big-font-size;
    justify-content: center;
  }

  .progress-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: $base-margin * 3;
  }

  .scheduled-message-wrapper {
    margin-bottom: $base-margin * 3;
  }

  .linear-progress-with-label-wrapper {
    display: flex;
    align-items: center;
    gap: $base-padding;

    .linear-progress {
      width: 100%;
    }
  }

  .details-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .details-item {
      display: flex;
      justify-content: space-between;
    }
  }
}

.circular-progress-with-label-wrapper {
  position: relative;
  display: inline-flex;

  .label-wrapper {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.localina-page .guests .localina-speeddial.import-metadata-speeddial {
  right: $base-padding * 15;
}
