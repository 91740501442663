.message {
  #message {
    height: calc(100% - #{$base-padding * 6});
    padding: #{$base-padding * 3} 0;
    overflow: auto;

    .form {
      padding: 0 #{$base-padding * 3};

      .localina-switch.has-label {
        justify-content: flex-start;

        label {
          width: auto;

          .MuiTypography-root {
            margin-right: $base-margin * 2;
          }
        }
      }
    }

    .title {
      color: $message-subtitle-color;
      padding: #{$base-padding * 4} #{$base-padding * 3} $base-padding;
    }

    .localina-switch {
      margin-left: $icon-size + $base-margin;
      width: calc(100% - #{$icon-size + $base-margin});
    }

    .localina-inputfield {
      width: auto;
    }

    .row {
      display: flex;
      margin: 0 -#{$base-margin};
      width: calc(100% + #{$base-margin * 2});

      .localina-datefield {
        margin: 0 #{$base-margin};
        max-width: $message-datefield-max-width;

        .MuiInputBase-root.Mui-disabled {
          .MuiInputAdornment-root {
            display: flex;
            opacity: 0.3;
          }

          .MuiOutlinedInput-notchedOutline {
            border: $border-size solid $border-color;
          }
        }
      }
    }

    .preview-confirmation-mail {
      padding-left: 0;

      p {
        font-size: 1.5rem;
      }
    }
  }

  .localina-speeddial {
    position: absolute;
  }
}
