.integration {
  padding-bottom: $base-padding * 3;

  .group {
    &:first-child {
      .localina-textarea {
        .MuiOutlinedInput-notchedOutline {
          border: $border-size solid $border-color;
        }
      }

      .qrcode {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: $base-padding * 4;
      }
    }

    .color-row {
      display: flex;
      justify-content: space-around;
      padding-bottom: $base-padding * 3;
    }

    .button-wrapper {
      text-align: center;
    }
  }

  &.widget {
    .group:not(:last-child) {
      padding-bottom: $base-padding * 4;
    }

    .title-divider {
      margin-bottom: 18px;
    }

    .title {
      margin-bottom: 18px;
    }

    .buttons button.Mui-disabled {
      border: 1px solid lightgrey;
      color: lightgrey;
    }
  }

  &.logo {
    .logo-preview {
      margin: 0 -#{$base-padding * 3};
      width: calc(100% + #{$base-padding * 6});
      background-color: $header-background-color;
      height: $header-height;
      margin-bottom: $base-padding * 6;
      display: flex;
      justify-content: center;

      & > * {
        padding: $base-padding 0;
      }
    }

    .row {
      display: flex;
      padding-bottom: $base-padding * 3;

      .localina-inputfield {
        padding-bottom: 0;
      }
    }

    .buttons {
      .localina-fileupload {
        margin: 0 $base-margin;
        flex-basis: 50%;

        .localina-button {
          margin: 0;
        }
      }
    }
  }

  &.feedback {
    .group > .localina-label.title {
      margin-bottom: $base-margin * 2;
    }
  }
}

.integration-view {
  .localina-drawer__body__content {
    display: flex;
    flex-direction: column;

    .platforms-content-container {
      height: 100%;
    }
  }
}
