.localina-searchfield {
  .MuiInputLabel-root:not(.MuiInputLabel-shrink) {
    top: -$base-padding;
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    height: $searchfield-height;
    min-height: $searchfield-height;
    padding-right: 0;

    .MuiInputBase-input {
      padding: #{$base-margin} #{$base-padding + $base-padding / 2};
    }

    .MuiInputAdornment-root {
      margin-left: 0;
    }

    .localina-iconbutton {
      height: $searchfield-height;
      width: $searchfield-height;
      border-radius: 0 $border-radius $border-radius 0;
      padding: 0;

      &:first-child {
        background-color: transparent;

        svg path {
          fill: $input-text-color;
        }
      }

      &:last-child {
        background-color: $searchfield-border-color;
        z-index: 1;

        svg path {
          fill: white;
        }
      }
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: $searchfield-border-color;
      }
    }
  }
}
