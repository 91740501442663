$localina-label-info-width: 70%;
$accordion-summary-color: #d4e2e7;
$accordion-details-color: #f0f5f7;
$capacity-content-width: 49%;
$button-group-width: 100px;

.shift-view {
  .group {
    & > .localina-label {
      padding-bottom: #{$base-padding + $base-padding / 2};
    }
  }

  .switch {
    display: flex;
    padding-bottom: $base-padding * 3;

    .localina-label {
      padding-right: $base-padding * 5;
    }
  }

  .shift-dates {
    display: flex;
    margin: 0 -#{$base-margin};
    width: calc(100% + #{$base-margin * 2});

    .localina-datefield,
    .localina-timefield {
      margin: 0 #{$base-margin};
    }
  }

  .row {
    .trash-icon-style {
      margin: 0 10px 0 0;
      padding-bottom: 24px;
    }

    .add-icon-style {
      margin-left: 10px;
    }
  }

  @media (min-width: 640px) {
    .row {
      display: flex;
      margin: 0 -#{$base-margin};
      width: calc(100% + #{$base-margin * 2});

      .localina-timefield {
        margin: 0 #{$base-margin};
      }
    }
  }

  .localina-label {
    &.left {
      &.info {
        width: #{$localina-label-info-width};
        margin-bottom: $base-padding * 6;
        padding-bottom: 0;
      }
    }
  }

  .localina-switch {
    padding-bottom: #{$base-padding};
  }

  .localina-buttongroup {
    padding-bottom: $base-padding * 4;
  }

  .title-configuration {
    margin-bottom: $base-padding * 2;
  }

  .title-capacity {
    padding-top: $base-padding * 3;
    padding-bottom: $base-padding * 3;
  }

  .title-areas {
    padding-top: $base-padding * 3;
    margin-bottom: $base-padding * 2;
  }

  .title-total-onlinecapacity {
    margin-top: $base-padding * 6;
  }

  .directly-bookable-area-list {
    margin-top: $base-padding;
  }

  .directly-bookable {
    margin-bottom: 0;
  }

  .bookable-list {
    display: flex;
    flex-direction: column;

    &-item {
      display: flex;
      justify-content: space-between;
    }
  }

  .right-content-total {
    float: right;
    display: inline;
    width: #{$capacity-content-width};
    text-align: end;
    font-weight: bold;
  }

  .overall-bookable-list {
    margin-top: $base-padding * 2;
    float: left;
    width: 100%;
  }

  .title-preview {
    margin-top: $base-padding * 6;
    float: left;
    width: 100%;
  }

  .bookable-areas-label {
    margin-top: $base-padding * 2;
    margin-bottom: $base-padding;
  }

  .availability-blocked-slot-wrapper {
    display: flex;
    width: 100%;
    margin-bottom: $base-margin * 2;

    .time-fields-wrapper {
      width: 100%;
      gap: $base-margin * 2;
      justify-content: space-between;
      display: flex;
      margin-right: 0;

      .localina-inputfield {
        padding-bottom: 0;
      }
    }

    .trash-icon-style.is-button {
      margin-left: $base-padding * 3;
    }

    @include media-breakpoint-down("xs") {
      .time-fields-wrapper {
        flex-direction: column;
        gap: $base-padding;
      }

      .trash-icon-style.is-button {
        margin-left: $base-padding * 2;
      }
    }
  }
}
