.localina-restaurant-public-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  height: 100%;
  overflow: auto;

  .rootdiv {
    width: 100%;
    height: 100%;
  }

  .MuiTabs-root {
    max-width: 100%;
  }

  .localina-rich-text-editor-container {
    width: 100%;

    .editor-content .tiptap.ProseMirror {
      border: none;
    }
  }
}
