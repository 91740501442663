.table-plan-assign-reservations-controller {
  background-color: $background-light-blue;
  display: flex;
  flex-direction: row;
  height: $table-plan-controller-height;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding-right: #{2 * $base-padding};

  .menu-container {
    padding-left: $base-padding;

    & > div {
      height: 100%;

      .icon {
        cursor: pointer;
        background: #ffffff;
        border: 1px solid #cfd4d9;
        border-radius: 4px;

        svg {
          width: #{$table-plan-controller-height - $base-padding};
          height: #{$table-plan-controller-height - $base-padding};
        }
      }
    }
  }

  .focus-blinking {
    animation: blink 166ms linear infinite;
  }

  @keyframes blink {
    0%,
    100% {
      background-color: white;
    }
    50% {
      background-color: lightgrey;
    }
  }

  .flexRow {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding-left: $base-padding;
  }

  .spacer {
    flex: 1;
  }

  .icon svg {
    height: 18px;
    width: 18px;
  }

  .icon:last-child {
    margin-right: $base-margin;
  }
}
