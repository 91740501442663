.menu.page-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .menu-integration-tabs {
    padding: 0 $base-padding * 2;
    background: #f0f5f7;
  }

  .tab-container {
    padding: $base-padding * 2;
    height: 100%;
    overflow: hidden;

    @media (max-width: 810px) {
      overflow: auto;
    }

    .menu-editor-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: $base-padding * 2;

      .localina-rich-text-editor-container {
        height: 100%;
        overflow: hidden;
        align-items: center;

        .editor-content {
          max-width: 700px;
          width: 100%;
        }
      }

      .days-tabs {
        overflow: auto;
        background: #f0f5f7;

        .MuiTabs-indicator {
          width: 100%;
          opacity: 0.3;
        }

        button {
          p {
            overflow: hidden;
            text-wrap: nowrap;
            text-overflow: ellipsis;
          }

          &.blank {
            background-color: white;
          }
        }
      }
    }

    .appearance-tab-container {
      display: flex;
      justify-content: center;
      gap: $base-padding * 2;
      height: 100%;
      overflow: hidden;

      @media (max-width: 810px) {
        flex-direction: column-reverse;
        height: 100%;
        justify-content: flex-end;
        overflow: unset;
      }

      .localina-restaurant-public-menu {
        border: 1px solid #093a5c;
        box-sizing: border-box;
      }

      .public-preview {
        max-width: 700px;
        min-width: 500px;
        max-height: 100%;

        display: flex;
        flex-direction: column;

        .localina-label {
          margin-bottom: $base-margin;
          justify-content: center;

          p {
            font-size: 20px;
          }
        }

        @media (max-width: 810px) {
          min-width: 280px;

          flex: 1;
          overflow: unset;
          height: auto;
        }
      }

      .MuiTypography-root {
        font-size: 16px;
        font-weight: normal;
        line-height: 19px;
        white-space: pre-line;
      }

      .additional-settings {
        display: flex;
        flex-direction: column;
        width: 500px;
        align-items: flex-end;
        gap: $base-padding * 2;
        overflow: auto;
        padding: $base-padding;

        .localina-colorpicker {
          display: flex;
          gap: $base-padding;
        }

        .select-font-family-wrapper {
          display: flex;
          gap: $base-padding;

          .MuiInputBase-root.select-field {
            min-width: 130px;
          }
        }

        .css-url-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .group {
          width: 100%;
        }

        .save-fab-button {
          display: none;
          position: fixed;
        }

        @media (max-width: 810px) {
          width: 100%;
          box-sizing: border-box;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 2%;
          overflow: unset;

          height: fit-content;

          @media (max-width: 540px) {
            gap: 5%;
          }

          .localina-colorpicker,
          .select-font-family-wrapper {
            display: flex;
            flex-direction: column;
            max-width: 130px;
            width: 23%;
            @media (max-width: 540px) {
              width: 40%;
              flex-basis: 40%;
            }
          }
          .localina-colorpicker .color {
            height: 46px;
            width: 100%;
          }
          .localina-label.label p {
            font-size: 14px;
            white-space: nowrap;
          }
          .css-url-wrapper {
            flex-direction: row;
            gap: 2%;
            align-items: center;
            margin-top: $base-margin;

            .buttons {
              width: auto;

              button {
                white-space: nowrap;
                flex-basis: auto;
              }
            }

            .localina-inputfield.localina-textfield {
              padding: 0;
            }
          }

          .save-fab-button {
            display: block;
          }
          .action-buttons-wrapper {
            display: none;
          }
        }

        .buttons button {
          flex-basis: content;
        }
      }
    }

    .menu-integrations-tab-container {
      height: 100%;
      display: flex;
      gap: $base-padding * 2;
      overflow: auto;
      align-items: flex-start;

      .divider-vertical {
        height: 100%;
        display: block;
      }

      .divider-horizontal {
        width: 100%;
        display: none;
      }

      @media (max-width: 810px) {
        align-items: center;
        flex-direction: column;

        .divider-vertical {
          display: none;
        }

        .divider-horizontal {
          display: block;
        }
      }

      .group {
        padding: $base-padding;
        width: 800px;
        max-width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        gap: $base-padding * 2;

        hr {
          border-width: 1px;
          width: 100%;
        }

        .localina-inputfield,
        .qrcode-container {
          flex-basis: 60%;
          max-width: 500px;
          box-sizing: border-box;
        }

        .localina-inputfield.localina-textarea {
          padding: 0;
        }

        .buttons {
          flex-direction: row;
          display: flex;
          align-items: flex-end;
          justify-content: center;

          button {
            width: fit-content;
            max-width: 250px;
            text-wrap: nowrap;
            flex-basis: 50%;
          }
        }

        @media (max-width: 810px) {
          flex-direction: column;
          gap: $base-padding * 2;
          padding-bottom: $base-padding * 2;

          .buttons {
            display: flex;
            flex-direction: row;
            width: 100%;
          }
        }
      }

      .qrcode {
        padding: 30px;

        svg {
          height: 100%;
          width: 100%;
        }
      }

      .qrcode-wrapper {
        width: fit-content;
        border: 10px solid #003a5c;
        border-radius: 20px;

        .qrcode-subtitle {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100px;
          background-color: #003a5c;
          color: white;

          p {
            font-size: 32px;
          }
        }
      }
    }
  }
}
