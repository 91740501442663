.localina-sortfield {
  display: flex;
  align-items: center;

  .localina-selectfield {
    padding-left: #{$base-padding + $base-padding / 2};
    padding-bottom: 0;

    .MuiInputLabel-root:not(.MuiInputLabel-shrink) {
      top: -$base-padding;
    }

    .MuiInputBase-root {
      height: $sortfield-height;
      min-height: $sortfield-height;

      .MuiSelect-select {
        padding: #{$base-margin} #{$base-padding + $base-padding / 2};
        padding-right: #{$icon-size + $base-padding * 2};
      }

      .MuiSelect-icon {
        top: 0;
        padding: $base-padding;
      }
    }
  }
}
