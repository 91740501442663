.localina-stepper.MuiStepper-root {
  height: $fabbutton-size;
  background-color: transparent;
  padding: 0;

  .MuiStepLabel-iconContainer {
    padding: 0;
  }

  .localina-fabbutton {
    background-color: $fabbutton-background-color;
    box-shadow: $shadow-offset $shadow-color;
    margin-right: 0;
    right: 0;
    bottom: 0;

    .label {
      color: $fabbutton-color;
    }

    &.inactive {
      background-color: $step-inactive-background-color;

      .label {
        color: $step-inactive-label-color;
      }
    }

    &.completed {
      background-color: $step-completed-background-color;

      .label {
        color: $step-completed-label-color;
      }
    }
  }

  .MuiStepConnector-line {
    border-color: $border-color;
  }
}
