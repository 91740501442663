$accordion-summary-color: #d4e2e7;
$accordion-details-color: #f0f5f7;

.request-mode-switch-accordion {
  margin-bottom: $base-margin * 2;

  .MuiPaper-root {
    box-shadow: none;
  }

  .MuiAccordionDetails-root {
    padding: $base-padding $base-padding * 3 $base-padding * 2;
    border-radius: 0 0 $base-padding * 2 $base-padding * 2;
    box-shadow: -1px 6px 4px -3px $shadow-color;

    background-color: $accordion-details-color;
  }

  .MuiAccordionSummary-root {
    padding: 0;

    &.Mui-expanded {
      min-height: auto;
    }

    .MuiAccordionSummary-content {
      margin: 0;

      &.Mui-expanded {
        background-color: $accordion-summary-color;
        border-radius: $base-padding * 2 $base-padding * 2 0 0;
        padding: $base-padding $base-padding 0;
      }
    }
  }

  .accordion-summary-style {
    .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
      transform: rotate(0deg);
    }
  }
}
