* {
  -webkit-overflow-scrolling: touch;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

.grow {
  flex-grow: 1;
}

.typoLink {
  cursor: pointer;
}

.row-direction {
  display: flex;
  flex-direction: row;
}

.padding-top {
  padding-top: $base-padding;
}

#onetrust-consent-sdk {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
