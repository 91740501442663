$side-background: rgba(1, 49, 65, 0.89);

.table-plan {
  display: flex;
  flex-direction: row;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 13px;

  .header {
    height: 16px;
    border-bottom: 0.5px solid grey;
    padding: $base-padding;
    background-color: $side-background;
    color: white;
  }

  .header-center {
    background-color: grey;
    display: flex;
    align-items: center;
    justify-content: center;

    .zoom-value {
      width: 24px;
    }

    .slider {
      -webkit-appearance: none;
      appearance: none;
      width: 60%;
      height: 8px;
      background: #d3d3d3;
      outline: none;
      opacity: 0.8;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;
      border-radius: 6px;
    }

    .slider:hover {
      opacity: 1;
    }

    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 16px;
      height: 16px;
      background: #52ace7;
      cursor: pointer;
      border-radius: 50%;
      box-shadow: 0 0 3px 3px white;
    }

    .slider::-moz-range-thumb {
      width: 16px;
      height: 16px;
      background: #52ace7;
      cursor: pointer;
    }
  }

  .table-plan-left {
    width: 150px;
    background-color: $side-background;

    .reservation-list-item {
      background-color: #52ace7;
      border-bottom: 0.5px solid grey;
      padding: $base-padding;
      cursor: pointer;
    }
  }

  .table-plan-canvas-container {
    flex: 1;
    overflow: hidden;

    .canvas {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
    }
  }

  .table-plan-objs-container {
    background-color: $side-background;
    width: 150px;

    .table-obj {
      margin: 20px;
      width: 100px;
      height: 50px;
      background-color: #4883c7;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
    }

    .table-circle-obj {
      margin: 20px;
      width: 50px;
      height: 50px;
      background-color: #4883c7;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
