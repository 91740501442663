$trash-icon-disabled-opacity: 0.3;

.localina-autocomplete {
  position: relative;

  .MuiPopper-root {
    width: 100%;
    z-index: 1500;
  }

  .MuiPaper-root {
    max-height: $autocomplete-max-height;
    box-shadow: 0 $shadow-size/2 $shadow-size $shadow-color;
    margin-bottom: #{$base-padding * 2};

    ul {
      padding: 0;
      max-height: $autocomplete-max-height;
      overflow: auto;

      .MuiListItemButton-root,
      .MuiButtonBase-root {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .localina-label {
          //width: 100%;

          &.label {
            color: $autocomplete-item-label-color;
            padding-bottom: #{$base-padding / 2};

            .highlight {
              color: $autocomplete-item-label-highlight-color;
            }
          }

          &.info {
            color: $autocomplete-item-info-color;
          }
        }
      }
    }
  }
}

.guest-info {
  .trash-icon-style {
    margin-top: $base-padding * 2;
    justify-content: left;
    margin-left: $base-padding;
  }

  .trash-icon-disabled-style {
    margin-top: $base-padding * 2;
    justify-content: left;
    margin-left: $base-padding;
    opacity: $trash-icon-disabled-opacity;
  }

  .favorite-icon-style {
    height: 19px !important;
    width: 20px !important;
  }

  .guest-info-title {
    margin-bottom: $base-padding * 3;
  }

  .form-field {
    padding-bottom: $base-padding * 3;
  }

  .guest-info-salutation {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .add-icon-style {
    float: left;
    cursor: pointer;
  }

  .guest-info-phone-numbers {
    margin-bottom: $base-padding * 8;
  }

  .guest-info-new-phone-number {
    width: 70%;
  }
}

.guest-option-wrapper {
  width: 100%;

  .guest-status-vip,
  .guest-status-blacklist {
    height: 20px;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  .row {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
  }
}
