.service-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  &.has-title {
    @include media-breakpoint-up("sm") {
      margin: 0 #{$base-padding * 3};
      box-shadow: $shadow-offset $shadow-color;
    }

    @include media-breakpoint-up("md") {
      margin: 0 0 0 0;
    }
  }

  .page-title {
    background-color: $page-title-background-color;

    &__content {
      display: flex;
      flex-direction: column;
      height: calc(100% - #{$base-padding * 4});
      padding: #{$base-padding * 2} #{$base-padding * 3};

      &__button {
        flex-grow: 1;

        .icon {
          justify-content: flex-start;
        }
      }

      &__title {
        display: flex;
        align-items: center;

        .icon {
          padding-right: #{$base-padding * 4};
        }

        .title {
          color: $page-title-color;

          .icon {
            padding-right: $base-padding;
          }
        }
      }
    }
  }

  .page-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .page-content {
    height: 100%;
    position: relative;
    overflow: auto;

    &.has-actions {
      //height: calc(100% - #{$page-actions-height});
    }
  }

  .page-actions {
    background-color: $page-actions-background-color;
    //height: $page-actions-height;

    &__content {
      padding: #{$base-padding + $base-padding / 2} #{$base-padding * 3};
    }

    .buttons {
      .localina-iconbutton {
        background-color: $secondary-button-background-color;
        border: $border-size solid $secondary-button-border-color;
        border-radius: $border-radius;
        margin: 0 $base-margin;

        .icon {
          display: flex;
        }
      }
    }
  }
}
