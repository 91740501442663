.maintenance {
  *:focus {
    outline: none;
  }

  &__icon {
    padding-top: 50px;

    svg {
      width: 150px;
      height: 150px;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: 500px;
    background-position: left -200px bottom -220px;
    background-repeat: no-repeat;
    overflow-y: auto;
    padding: 40px;

    *:focus {
      outline: none;
    }
  }

  &__text-block {
    display: flex;
    justify-content: center;
    padding: (4 * $base-margin) (2 * $base-margin);
  }
}
