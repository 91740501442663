$localina-label-info-width: 70%;
$accordion-summary-color: #d4e2e7;
$accordion-details-color: #f0f5f7;

.specialopeninghour-view {
  .switch {
    display: flex;
    padding-bottom: $base-padding * 3;

    .localina-label {
      padding-right: $base-padding * 5;
    }
  }

  .localina-drawer__body__content {
    padding-top: $base-padding * 2;
  }

  .row {
    display: flex;
    margin: 0 -#{$base-margin};
    width: calc(100% + #{$base-margin * 2});

    .localina-datefield,
    .localina-timefield {
      margin: 0 #{$base-margin};
    }
  }

  .localina-switch {
    padding-bottom: #{$base-padding};
  }

  .localina-buttongroup {
    padding-bottom: $base-padding * 4;
  }

  .localina-label {
    &.left {
      &.info {
        width: #{$localina-label-info-width};
        margin-bottom: $base-padding * 6;
      }
    }
  }

  .localina-drawer__footer {
    margin-top: $base-padding * 2;
  }

  .title-areas {
    padding-top: $base-padding * 3;
    margin-bottom: $base-padding * 2;
  }

  .title-capacity {
    padding-top: $base-padding * 3;
    padding-bottom: $base-padding * 3;
  }

  .title-configuration {
    margin-bottom: $base-padding * 2;
  }

  .typography-margin-left {
    margin-left: $base-padding * 2;
  }

  .title-total-onlinecapacity {
    margin-top: $base-padding * 6;
  }

  .directly-bookable-area-list {
    margin-top: $base-padding;
  }

  .directly-bookable {
    margin-bottom: 0px;
  }

  .left-content {
    float: left;
    display: inline;
    width: #{$capacity-content-width};
  }

  .right-content {
    float: right;
    display: inline;
    width: #{$capacity-content-width};
    text-align: end;
  }

  .right-content-total {
    float: right;
    display: inline;
    width: #{$capacity-content-width};
    text-align: end;
    font-weight: bold;
  }

  .overall-bookable-list {
    margin-top: $base-padding * 2;
    float: left;
    width: 100%;
  }

  .title-preview {
    margin-top: $base-padding * 6;
    float: left;
    width: 100%;
  }

  .bookable-areas-label {
    margin-top: $base-padding * 2;
    margin-bottom: $base-padding;
  }

  .availability-blocked-slot-wrapper {
    display: flex;

    .time-fields-wrapper {
      display: flex;
      margin-right: 0;
    }
  }
}
