.localina-alert {
  display: flex;
  align-items: flex-start;
  padding: $base-padding;
  margin: 0 #{$base-padding * 3};
  box-shadow: #{$shadow-size / 2} #{$shadow-size / 2} $shadow-size $shadow-color;
  z-index: $zindex-drawer;
  position: absolute;
  bottom: $base-padding * 3;
  left: 0;
  right: 0;

  &.error {
    background-color: $alert-error-background-color;
    color: $alert-error-color;

    svg path {
      fill: $alert-error-color;
    }
  }

  &.success {
    background-color: $alert-success-background-color;
    color: $alert-success-color;

    svg path {
      fill: $alert-success-color;
    }
  }

  .text {
    padding: 0 #{$base-padding * 2};
    flex-grow: 1;
  }
}
