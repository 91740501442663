.localina-accordion.MuiAccordion-root {
  box-shadow: none;

  .MuiAccordionSummary {
    &-root {
      padding: 0 #{$base-padding * 3};
      min-height: $accordion-summary-height;
    }

    &-content {
      margin: 0;
    }

    &-expandIcon {
      margin-right: 0;
      padding: $base-padding #{$base-padding + $base-padding / 2};
    }
  }

  .MuiAccordionDetails-root {
    padding: $base-padding #{$base-padding * 5};
  }
}
