.collapsible-table-plans-overview {
  z-index: 3000;
  background: white;
  height: 100%;
  box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.25);
  min-width: 15px;

  position: absolute;
  right: -12px;
  top: 0;
  bottom: 0;

  li,
  p {
    white-space: nowrap !important;
  }

  & > div {
    height: 100%;

    &.MuiCollapse-entered {
      max-width: 80vw;
      @include media-breakpoint-down("xs") {
        max-width: 95vw;
      }

      .MuiCollapse-wrapperInner {
        width: 100%;
      }
    }

    .MuiCollapse-wrapperInner > #scrolling-container {
      overflow-y: auto;
      overflow-x: hidden;

      width: 100%;
      height: 100%;

      li.MuiListSubheader-sticky {
        background: white;
        width: 100%;
        z-index: 5000;
      }
    }
  }

  .mini-table-plan-canvas-wrapper {
    position: relative;
    height: 100%;
    max-height: 300px;
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-left: 30px;
    border: 1px dashed transparent;

    &.hovering-with-reservation {
      background: #eeeeee;
    }

    &.focus-blinking {
      animation: blink 166ms linear infinite;
    }

    @keyframes blink {
      0%,
      100% {
        background-color: white;
      }
      50% {
        background-color: lightgrey;
      }
    }

    &.hovering-outside {
      opacity: 0.4;
    }

    .table-plan-assign-reservations-canvas-wrapper {
      position: absolute !important;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  .localina-collapse-button {
    position: absolute;
    left: 0;
    top: 50%;
    transform: rotate(90deg) translate(-50%, 50%);
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 5001;
    box-shadow: inset 0 3px 4px 0 rgba(0, 0, 0, 0.25);

    background-color: #f0f5f7;

    .icon svg {
      height: 32px;
      width: 32px;
    }
  }
}

.app-container__body {
  overflow-x: hidden;
}
