.reports {
  &-container {
    padding: $base-margin * 2;

    @include media-breakpoint-down("xs") {
      padding: $base-margin * 2 $base-margin * 2 $base-margin * 10;
    }

    & > .localina-buttongroup {
      padding-bottom: $base-padding * 3;
    }
  }

  .controller-wrapper {
    display: flex;
    justify-content: flex-end;

    .switches {
      width: 100%;
      display: flex;
    }

    @include media-breakpoint-down("sm") {
      flex-direction: column;
      .localina-switch.has-label {
        padding-bottom: $base-padding;
      }
      .switches {
        padding-bottom: $base-padding * 2;
        flex-direction: column;
      }
    }
  }

  &-chart {
    border: $border-size $border-color;
    border-style: solid none;
    padding: ($base-margin * 2) 0;
    margin: ($base-margin * 2) 0;
  }

  .MuiFormControlLabel-root .MuiFormControlLabel-label {
    @include media-breakpoint-up("sm") {
      flex-grow: 0;
    }
  }

  .localina-tabs {
    box-shadow: 0 6px 4px -3px $shadow-color;
    margin-bottom: $base-padding;
    padding: 0 $base-padding * 3 0;
  }

  .chart {
    &.pie-chart {
      width: 100%;

      @include media-breakpoint-down("sm") {
        .MuiChartsLegend-root {
          display: none;
        }
      }

      g:not(.MuiChartsLegend-root) > g[transform] {
        transform: translate(50%, 50%);
      }
    }
  }

  .row {
    display: flex;
    margin: 0 -#{$base-margin};
    width: calc(100% + #{$base-margin} * 2);

    .localina-datefield {
      margin: ($base-margin * 2) $base-margin;
      padding: 0;
    }
  }

  .localina-button {
    flex-basis: 150px;
  }

  .localina-iconbutton {
    background-color: $secondary-button-background-color;
    border: $border-size solid $border-color;
    border-radius: $border-radius;
    margin: 0 $base-margin/2;
    cursor: pointer;

    .icon {
      display: flex;
    }
  }

  .info-text {
    margin-top: $base-margin * 2;
  }
}
