.localina-dialog {
  .MuiDialog-paper {
    padding: $base-padding * 2;

    .text {
      padding-bottom: $base-padding;

      & ~ .text {
        padding-bottom: $base-padding * 5;
      }
    }

    .button {
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: 0;
    }

    @include media-breakpoint-down("xs") {
      .buttons {
        flex-direction: column;
        align-items: center;
        margin: 0;
        width: unset;
        height: unset;
        gap: $base-padding;
      }
    }
  }

  &.confirm-with-text-copy {
    .text-to-copy-container {
      display: flex;
      margin-bottom: $base-margin * 2;
    }
  }
}

.localina-copyOrLinkDialog {
  .MuiDialog-paper {
    padding: $base-padding * 2;
    width: 600px;

    .text {
      padding-bottom: $base-padding;

      & ~ .text {
        padding-bottom: $base-padding * 5;
      }
    }

    .buttons {
      display: flex;
      justify-content: space-around;
      gap: 20px;
      height: $button-height;
      min-height: $button-height;
      margin: 0;
      width: 100%;
    }

    .buttons button,
    .buttons a {
      margin: 0 !important;
      white-space: nowrap;
      flex: 1;
      text-decoration: none;
    }

    @include media-breakpoint-down("xs") {
      min-height: 300px;
      .buttons {
        flex-direction: column;
      }
    }
  }
}

.reservations-export-choice {
  .localina-label.text:nth-child(2) {
    color: $header-background-color;
    font-style: italic;
  }
}
