.localina-drawer.MuiDrawer-root {
  z-index: $zindex-drawer;

  .MuiDrawer-paper {
    width: $drawer-width;
    max-width: $drawer-max-width;

    @include media-breakpoint-down("xs") {
      max-width: 93%;
    }
  }
}
