.restaurants-plan-icon {
  height: $icon-size-small;
  width: $icon-size-small;
}

.margin-bottom {
  margin-bottom: $base-padding;
}

.margin-top {
  margin-top: $base-padding;
}

.margin-right {
  margin-right: $base-padding;
}

table.MuiChartsTooltip-table {
  tbody {
    min-width: 130px;

    td {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0.000938em;
    }
  }
}

text.MuiPieArcLabel-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.000938em;
}

.MuiChartsLegend-root.MuiChartsLegend-column {
  transform: translateX(40px);
}
