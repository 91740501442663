.localina-page {
  height: 100%;
  background-color: white;

  header {
    height: $header-height;
    min-height: $header-height;
    z-index: $zindex-header;
    background-color: $header-background-color;
    display: flex;
    justify-content: center;
    padding: 0 #{$base-padding * 3};

    .localina-page__header__content {
      width: 100%;
    }

    .localina-page__header__title-bar {
      display: flex;
      height: $header-height;
      color: $header-title-color;
      width: 100%;

      .title {
        text-transform: uppercase;
        padding-bottom: 0;
      }

      .bar-button {
        display: flex;
        align-items: center;
      }

      .menu .icon path {
        fill: $header-title-color;
      }

      .icon {
        display: flex;

        &.notifications-available path {
          fill: $notification-color;
        }
      }

      .localina-iconbutton {
        margin-left: 2 * $base-margin;
        background-color: $secondary-button-background-color;
        border: $border-size solid $border-color;
        border-radius: $border-radius;

        .icon {
          display: flex;

          &.notifications-available path {
            fill: $notification-color;
          }
        }
      }
    }

    &.stepper {
      height: $header-stepper-height;
      min-height: $header-stepper-height;

      .localina-page__header__content {
        position: relative;
      }

      .localina-stepper {
        position: absolute;
        left: -$base-padding;
        right: -$base-padding;
        bottom: -#{$base-padding * 2};
        z-index: $zindex-header;
      }

      & ~ main {
        height: calc(100% - #{$header-stepper-height});

        .localina-page__body {
          padding-top: $base-padding * 6;
          min-height: calc(100% - #{$base-padding * 11});

          &.has-footer {
            min-height: calc(100% - #{$base-padding * 11 + $footer-height});
          }
        }
      }
    }

    & ~ main {
      height: calc(100% - #{$header-height});
    }
  }

  .localina-page__banner {
    .highlight {
      color: #d32f2f;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  main {
    height: 100%;
    z-index: 0;
    overflow: auto;

    .localina-page__body {
      display: flex;
      justify-content: center;
      min-height: calc(100% - #{$base-padding * 9});
      padding: #{$base-padding * 4} #{$base-padding * 3} #{$base-padding * 5};

      &.has-footer {
        min-height: calc(100% - #{$base-padding * 9 + $footer-height});
      }

      &__content {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        width: 100%;
      }
    }
  }

  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $footer-background-color;
    height: $footer-height;
    min-height: $footer-height;
  }
}
