.localina-label {
  display: flex;
  align-items: center;

  &.left {
    justify-content: flex-start;
    text-align: left;
  }

  &.center {
    justify-content: center;
    text-align: center;
  }

  &.right {
    justify-content: flex-end;
    text-align: right;
  }

  &.is-required::after {
    content: "*";
    margin-left: $base-margin/2;
  }

  &.has-icon {
    .icon {
      height: $icon-size;
      width: $icon-size;
    }

    .MuiTypography-root {
      margin-left: $base-margin;
    }
  }

  &.use-icon {
    .MuiTypography-root {
      margin-left: $icon-size + $base-margin;
    }
  }

  &.link,
  &.is-button {
    cursor: pointer;
  }

  &.error {
    .MuiTypography-root {
      color: $error-color;
    }
  }

  &.link {
    .MuiTypography-root {
      color: $link-color;
    }
  }
}

.localina-label {
  &.title {
    h3.MuiTypography-root {
      font-size: 48px;
      font-weight: normal;
      line-height: 56px;
      white-space: pre-line;
    }

    h4.MuiTypography-root {
      font-size: 36px;
      font-weight: normal;
      line-height: 42px;
      white-space: pre-line;
    }

    h5.MuiTypography-root {
      font-size: 24px;
      font-weight: normal;
      line-height: 28px;
      white-space: pre-line;
    }

    h6.MuiTypography-root {
      font-size: 20px;
      font-weight: normal;
      line-height: 23px;
      white-space: pre-line;
    }
  }

  &.info {
    .MuiTypography-root {
      font-size: 12px;
      font-weight: normal;
      line-height: 14px;
      white-space: pre-line;
    }
  }

  &.link,
  &.text {
    .MuiTypography-root {
      font-size: 16px;
      font-weight: normal;
      line-height: 19px;
      white-space: pre-line;
    }
  }

  &.label {
    .MuiTypography-root {
      font-size: 14px;
      font-weight: normal;
      line-height: 16px;
      white-space: pre-line;
    }
  }

  &.bold {
    .MuiTypography-root {
      font-weight: bold;
    }
  }
}

.Mui-selected {
  .localina-label.label {
    .MuiTypography-root {
      min-height: 16px;
    }
  }
}
