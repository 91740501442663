.users {
  .localina-list {
    height: calc(100% - #{$base-padding * 6});
    padding: #{$base-padding * 3} 0;
    overflow: auto;
  }

  .localina-fabbutton {
    background-color: $speeddial-background-color;
  }
}
