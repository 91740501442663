.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: $icon-size;
    width: $icon-size;
  }

  &.use-defailt {
    svg path {
      fill: $icon-color;
    }
  }

  &.big {
    svg {
      height: $icon-size-big;
      width: $icon-size-big;
    }
  }

  &.is-button {
    svg {
      cursor: pointer;
    }
  }
}
